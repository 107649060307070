<script>
  import { createEventDispatcher } from "svelte";
  import { notify } from "$utils/notify";
  import { showUploader } from "$utils/upload";

  import Button from "$components/Button.svelte";
  import Form from "$components/Form.svelte";
  import Video from "$components/Video.svelte";
  import Input from "$components/Input.svelte";

  import Ajax from "$utils/ajax";

  const dispatch = createEventDispatcher();

  export let block = null;

  let saving = false;
  let videoUrl = block ? block.url : "";
  let title = block ? block.title : "";

  const saveVideo = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    let sharedData = {
      url: videoUrl,
      title: title,
      block_type: "video",
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, sharedData)
      : await Ajax.post("/dashboard/blocks", sharedData);

    if (ajaxErrors) {
      notify({
        type: "error",
        text: block ? "Unable to update video" : "Unable to save video",
      });
    } else {
      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };

  const handleUpload = async () => {
    try {
      const uploadedUrl = await showUploader({ fileType: "video" });
      videoUrl = uploadedUrl;
    } catch (error) {
      notify({
        type: "error",
        text: "Failed to upload video",
      });
    }
  };

  const clearVideo = () => {
    videoUrl = "";
  };
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit Video" : "Add Video"}
</h2>

<Form on:submit={saveVideo}>
  <div class="flex flex-col gap-8">
    <Input
      label="Video Title"
      name="title"
      bind:value={title}
      placeholder="Enter video title"
      required
    />
    <div class="flex flex-col gap-4">
      <div class="flex gap-4">
        <Button type="button" on:click={handleUpload} klass="flex-1 h-12">
          Select Video
        </Button>

        {#if videoUrl}
          <Button
            type="button"
            on:click={clearVideo}
            klass="h-12 px-4 bg-red-500 hover:bg-red-600 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </Button>
        {/if}
      </div>
      <div class="mb-4">
        {#if videoUrl}
          <Video src={videoUrl} />
        {:else}
          <div
            class="bg-gray-100 p-4 text-center text-gray-500 h-48 flex items-center justify-center"
          >
            <span>No video uploaded</span>
          </div>
        {/if}
      </div>
    </div>
    <Button
      type="submit"
      klass="w-full h-12"
      disabled={saving || !videoUrl || !title}
    >
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update Video" : "Save Video"}
    </Button>
  </div>
</Form>
