<script>
  import { createEventDispatcher } from "svelte";

  import Button from "$components/Button.svelte";
  import Input from "$components/Input.svelte";
  import Form from "$components/Form.svelte";
  import Ajax from "$utils/ajax";

  const dispatch = createEventDispatcher();

  export let block = null;

  let url = block?.url || "";
  let title = block?.title || "Schedule Meeting";
  let saving = false;

  let errors = {};

  const saveCalendly = async (event) => {
    event.preventDefault();

    if (saving) return;
    saving = true;

    const data = {
      block_type: "calendly",
      url,
      title,
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, data)
      : await Ajax.post("/dashboard/blocks", data);

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      if (!block) {
        url = "";
        title = "Schedule Meeting";
      }

      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }
      dispatch("close");
    }

    saving = false;
  };
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit" : "Add"} Calendly Integration
</h2>

<Form on:submit={saveCalendly}>
  <div class="flex flex-col gap-8">
    <Input
      bind:value={url}
      type="url"
      label="Enter Calendly URL"
      required
      klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
      error={errors.url}
    />
    <Input
      bind:value={title}
      type="text"
      label="Enter Button Title"
      required
      klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
      error={errors.title}
    />
    <Button type="submit" klass="w-full h-12" disabled={saving}>
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update" : "Add"} Calendly Integration
    </Button>
  </div>
</Form>
