<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";
  import Video from "$components/Video.svelte";
  import { fade, fly } from "svelte/transition";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;

  const dispatch = createEventDispatcher();

  let videoFrame;
  let transitionDuration = "200ms";
  let showModal = false;
  let videoAspectRatio = 16 / 9; // Default aspect ratio

  function toggleModal() {
    showModal = !showModal;
    dispatch("block-expand", { blockId: block.id, expanded: showModal });
  }

  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, transitionDuration);
  $: blockRadius = getBorderRadius(buttonStyle);

  onMount(() => {
    dispatch("mounted");
  });

  function handleVideoMetadata(event) {
    const video = event.target;
    videoAspectRatio = video.videoWidth / video.videoHeight;
  }
</script>

<div
  class="overflow-hidden transition-all ease-in-out w-full relative"
  style={currentButtonStyle}
  use:useHoverEffect={{
    theme,
    buttonStyle,
    hoverEffect,
    transitionDuration,
    getBlockStyle,
  }}>
  <div
    class="flex flex-col transition-colors ease-in-out cursor-pointer"
    style="
      color: {buttonStyle.startsWith('fill')
      ? 'var(--buttonTextColor)'
      : 'var(--linkColor)'};
      transition-duration: {transitionDuration};
    "
    on:click={toggleModal}>
    <div class="flex items-center p-4">
      <div
        class="w-12 h-12 mr-4 flex items-center justify-center flex-shrink-0 bg-black overflow-hidden"
        style="
          border-radius: {blockRadius};
        ">
        <Video src={block.url} allowPlay={false} />
      </div>
      <div class="flex-grow">
        <h2
          class="line-clamp-2 cursor-pointer"
          style="
          font-weight: var(--titleFontWeight);
          font-size: calc(var(--titleFontSize) * 0.8);
        ">
          {block.title}
        </h2>
      </div>
    </div>
  </div>
</div>

{#if showModal}
  <div
    class="fixed inset-0 flex flex-col justify-center items-center z-50"
    on:click={toggleModal}
    transition:fade={{ duration: 100 }}>
    <div class="absolute inset-0 bg-black opacity-75"></div>
    <div
      class="bg-transparent rounded-lg w-full md:w-4/5 max-w-3xl relative z-10 px-2 md:px-0 flex flex-col items-center"
      on:click|stopPropagation
      transition:fly={{ y: 200, duration: 500 }}>
      <div
        class="relative w-full"
        style="max-height: calc(100vh - 120px); {videoAspectRatio < 1
          ? 'max-width: 60vh;'
          : ''}">
        <Video
          bind:this={videoFrame}
          src={block.url}
          allowPlay={true}
          on:loadedmetadata={handleVideoMetadata} />
      </div>
      <div class="mt-4" transition:fly={{ y: 50, duration: 500, delay: 200 }}>
        <button
          class="bg-white text-gray-800 rounded-full px-4 py-2 flex items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 shadow-lg"
          on:click|stopPropagation|preventDefault={toggleModal}>
          <i class="fas fa-times mr-2"></i>
          <span>Close</span>
        </button>
      </div>
    </div>
  </div>
{/if}
