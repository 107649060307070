<script>
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";
  import { createEventDispatcher, onMount } from "svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;

  let transitionDuration = "200ms";

  let imageSize = block?.fields?.size || "100%";

  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, transitionDuration);
  $: blockRadius = getBorderRadius(buttonStyle);

  const dispatch = createEventDispatcher();

  onMount(() => {
    dispatch("mounted");
  });
</script>

{#if block.fields.show_only_image}
  {#if block.image_url}
    <img
      src={block.image_url}
      alt={block.title}
      class="w-full h-auto object-cover mx-auto"
      style={`border-radius: ${blockRadius}; width: ${imageSize};`}
    />
  {:else}
    <div
      class="w-full h-48 flex items-center justify-center mx-auto"
      style="
        background-color: var(--imagePlaceholderColor);
        border-radius: ${blockRadius};
        width: ${imageSize};
      "
    >
      <span
        style="
          color: var(--imagePlaceholderTextColor);
          font-weight: var(--titleFontWeight);
          font-size: var(--titleFontSize);
        ">{block?.title?.[0]}</span
      >
    </div>
  {/if}
{:else}
  <div
    class="w-full overflow-hidden transition-all ease-in-out"
    style={currentButtonStyle}
    use:useHoverEffect={{
      theme,
      buttonStyle,
      hoverEffect,
      transitionDuration,
      getBlockStyle,
    }}
  >
    <div class="p-4">
      {#if block.image_url}
        <img
          src={block.image_url}
          alt={block.title}
          class="w-full h-auto object-cover mx-auto"
          style={`border-radius: ${blockRadius}; width: ${imageSize};`}
        />
      {:else}
        <div
          class="w-full h-48 flex items-center justify-center mx-auto"
          style="
            background-color: var(--imagePlaceholderColor);
            border-radius: ${blockRadius};
            width: ${imageSize};
          "
        >
          <span
            style="
              color: var(--imagePlaceholderTextColor);
              font-weight: var(--titleFontWeight);
              font-size: var(--titleFontSize);
            ">{block?.title?.[0]}</span
          >
        </div>
      {/if}
    </div>
  </div>
{/if}
