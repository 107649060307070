<script>
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";
  import { createEventDispatcher, onMount } from "svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;

  let transitionDuration = "200ms";

  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, transitionDuration);
  $: blockRadius = getBorderRadius(buttonStyle);

  const dispatch = createEventDispatcher();

  onMount(() => {
    dispatch("mounted");
  });
</script>

<div
  class="overflow-hidden transition-all ease-in-out w-full"
  style="{currentButtonStyle} border-radius: {blockRadius};"
  use:useHoverEffect={{
    theme,
    buttonStyle,
    hoverEffect,
    transitionDuration,
    getBlockStyle,
  }}
>
  <div
    class="p-4 transition-colors ease-in-out"
    style="
      color: {buttonStyle && buttonStyle.startsWith('fill')
      ? 'var(--buttonTextColor)'
      : 'var(--linkColor)'};
      transition-duration: {transitionDuration};
      border-radius: {blockRadius};
    "
  >
    <div class="flex-grow">
      <div
        class="tiptap"
        style="
        font-weight: var(--textFontWeight);
        font-size: var(--textFontSize);
      "
      >
        {@html block.content.replaceAll("<p></p>", "<p><br /></p>")}
      </div>
    </div>
  </div>
</div>
