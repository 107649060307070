<script>
  import { onMount } from "svelte";
  export let profileUser;

  let googleAnalyticsId = profileUser.google_analytics_id;
  let facebookPixelId = profileUser.facebook_pixel_id;
  let facebookConversionApiToken = profileUser.facebook_conversion_api_token;

  function initGoogleAnalytics() {
    if (!googleAnalyticsId) return;

    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", googleAnalyticsId);
  }

  function initFacebookPixel() {
    if (!facebookPixelId) return;

    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", facebookPixelId);
    fbq("track", "PageView");
  }

  onMount(() => {
    initGoogleAnalytics();
    initFacebookPixel();
  });
</script>

<div class="page-container">
  <slot />
</div>

<style>
  .page-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
</style>
