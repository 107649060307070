<script>
  import { createEventDispatcher } from "svelte";
  import Actions from "$app-components/blocks/Actions.svelte";
  import Video from "$components/Video.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block", { id: block.id });
  }

  $: ({ enabled, title, url } = block);
</script>

<div class="flex flex-col bg-white rounded-lg shadow">
  <div class="flex items-center justify-between p-4">
    <div class="flex items-center space-x-4 flex-grow overflow-hidden">
      <div class="cursor-move">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>
      <div class="flex items-center space-x-4 bg">
        <div
          class="h-12 w-12 flex-shrink-0 flex items-center bg-black overflow-hidden rounded-lg"
        >
          <Video src={url} allowPlay={false} />
        </div>
        <div class="flex-grow overflow-hidden flex items-center">
          <h3 class="text-lg font-semibold text-gray-800 truncate">{title}</h3>
        </div>
      </div>
    </div>
    <Actions {block} {updater} on:delete-block={handleDelete} />
  </div>
</div>
