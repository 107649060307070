<script>
  import DefaultLayout from "$layouts/Default.svelte";
  import { router } from "@inertiajs/svelte";

  import Form from "$components/Form.svelte";
  import Input from "$components/Input.svelte";
  import Button from "$components/Button.svelte";
  import Card from "$components/Card.svelte";
  import Checkbox from "$components/Checkbox.svelte";
  import Link from "$components/Link.svelte";
  import Logo from "$components/Logo.svelte";

  import Ajax from "$utils/ajax";

  import { notify } from "$utils/notify";

  export let email;

  let login = async (event) => {
    let data = event.detail;
    const [errors, resp] = await Ajax.post("/login", data);

    if (errors) {
      notify({ message: errors.join("\n"), type: "error" });
    } else {
      router.visit("/dashboard");
    }
  };
</script>

<DefaultLayout>
  <div class="flex justify-center mb-16">
    <Logo />
  </div>

  <Card size="lg" klass="mx-auto max-w-sm">
    <svelte:fragment slot="title">
      <h2 class="text-2xl font-extrabold text-center mb-6">Login</h2>
      <p class="text-sm text-gray-600 text-center mb-8">
        Don't have an account? <Link to="/register">Sign up</Link> for an account
        now.
      </p>
    </svelte:fragment>

    <svelte:fragment slot="content">
      <div class="pt-2">
        <Form on:submit={login}>
          <div class="space-y-5 flex flex-col gap-2">
            <Input
              name="email"
              type="email"
              label="Email"
              required
              value={email}
            />
            <Input name="password" type="password" label="Password" required />

            <div class="flex justify-between items-center">
              <Checkbox name="remember" label="Keep me logged in" />
              <Link to="/users/password/new" variant="secondary" klass="text-sm"
                >Forgot your password?</Link
              >
            </div>

            <div>
              <Button
                type="submit"
                enableAnimation={true}
                klass="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md transition mt-2 duration-300"
              >
                Log in <span aria-hidden="true" class="ml-2">→</span>
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </svelte:fragment>
  </Card>
</DefaultLayout>
