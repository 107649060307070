import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import AwsS3 from '@uppy/aws-s3';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const buildCloseButton = (dashboardElement) => {
  const closeButton = document.createElement('button');
  
  const styles = {
    position: 'absolute',
    top: 'calc(50vh - 25vh - 35px)',
    right: 'calc(50vw - 25vh - 35px)',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '2px solid #fff',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '18px',
    color: '#fff',
    transition: 'all 0.2s ease',
    zIndex: '10000'
  };

  Object.assign(closeButton.style, styles);
  closeButton.innerHTML = '<svg width="14" height="14" viewBox="0 0 14 14"><path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="currentColor"/></svg>';

  const hover = () => {
    closeButton.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
    closeButton.style.transform = 'scale(1.1)';
  };

  const unhover = () => {
    closeButton.style.backgroundColor = 'transparent';
    closeButton.style.transform = 'scale(1)';
  };

  closeButton.addEventListener('mouseover', hover);
  closeButton.addEventListener('mouseout', unhover);
  closeButton.addEventListener('click', () => document.body.removeChild(dashboardElement));

  return closeButton;
};

const buildDashboardElement = () => {
  if (document.getElementById('uppy-dashboard')) {
    return;
  }

  const dashboardElement = document.createElement('div');
  dashboardElement.id = 'uppy-dashboard';
  dashboardElement.style.position = 'fixed';
  dashboardElement.style.top = '0';
  dashboardElement.style.left = '0';
  dashboardElement.style.width = '100%';
  dashboardElement.style.height = '100%';
  dashboardElement.style.zIndex = '9999';
  dashboardElement.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  dashboardElement.style.display = 'flex';
  dashboardElement.style.justifyContent = 'center';
  dashboardElement.style.alignItems = 'center';

  dashboardElement.appendChild(buildCloseButton(dashboardElement));
  document.body.appendChild(dashboardElement);
};

export const createUploader = (options = {}) => {
  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: options.fileType === 'video' ? ['video/*'] : ['image/*']
    },
    ...options
  });

  buildDashboardElement();

  uppy.use(Dashboard, {
    inline: true,
    target: '#uppy-dashboard',
    height: '50vh',
    width: '50vh'
  });

  uppy.use(AwsS3, {
    getUploadParameters: async (file) => {
      const response = await fetch('/api/upload/presigned_url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filename: file.name,
          content_type: file.type
        })
      });

      const { url, fields } = await response.json();

      return {
        method: 'POST',
        url: url,
        fields: fields
      };
    }
  });

  uppy.on('file-added', async (file) => {
    if (options.crop && file.type.startsWith('image/')) {
      const result = await options.crop(file.data);

      result.on("process", async (event) => {
        uppy.setFileState(file.id, {
          data: event.dest
        });

        uppy.upload();
      });
    }
  });

  uppy.on('complete', () => {
    const dashboardElement = document.getElementById('uppy-dashboard');

    if (dashboardElement) {
      dashboardElement.remove();
    }

    document.body.classList.remove('uppy-Dashboard-isFixed');
  });

  return uppy;
};

export const showUploader = (options = {}) => {
  const uppy = createUploader(options);
  uppy.getPlugin('Dashboard').openModal();

  return new Promise((resolve, reject) => {
    uppy.on('complete', (result) => {
      if (result.successful && result.successful.length > 0) {
        resolve(result.successful[0].uploadURL);
      } else {
        reject(new Error('Upload failed'));
      }
    });

    uppy.on('error', (error) => {
      reject(error);
    });
  });
};
