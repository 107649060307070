<script>
  import { createEventDispatcher, onMount } from "svelte";

  export let block;

  const dispatch = createEventDispatcher();

  $: ({ title, fields } = block);
  $: level = fields?.level || "h3";

  $: fontSize = {
    h1: "2.5rem",
    h2: "2rem",
    h3: "1.75rem",
    h4: "1.5rem",
    h5: "1.25rem",
    h6: "1rem",
  }[level];

  $: fontWeight = {
    h1: "800",
    h2: "700",
    h3: "600",
    h4: "600",
    h5: "600",
    h6: "600",
  }[level];

  onMount(() => {
    dispatch("mounted");
  });
</script>

<svelte:element
  this={level}
  class="heading-block w-full flex justify-center"
  style="font-size: {fontSize}; font-weight: {fontWeight};"
>
  {title}
</svelte:element>

<style>
  .heading-block {
    color: var(--textColor);
    text-align: center;
    word-wrap: break-word;
    line-height: 1.2;
  }
</style>
