<script>
  import { onMount } from "svelte";

  export let locations;

  let mounted = false;

  onMount(() => {
    mounted = true;
  });

  function getCountryFlag(countryCode) {
    return `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;
  }

  function getProgressBarWidth(visits, maxVisits) {
    return (visits / maxVisits) * 100;
  }

  $: maxVisits =
    locations.length > 0 ? Math.max(...locations.map((c) => c.visits)) : 0;
</script>

<div class="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
  <div class="space-y-4">
    {#each locations as country, index}
      {#if mounted}
        <div
          class="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-4"
        >
          <div class="flex items-center sm:w-1/4">
            <img
              src={getCountryFlag(country.countryCode)}
              alt={`${country.country} flag`}
              class="w-8 sm:w-10 h-auto rounded-sm shadow-sm mr-2"
            />
            <span class="font-semibold text-gray-700 truncate ml-2"
              >{country.country}</span
            >
          </div>
          <div class="flex-grow sm:flex sm:justify-between sm:items-center">
            <div
              class="bg-gray-200 h-2 rounded-full overflow-hidden sm:w-1/2 hidden sm:block"
            >
              <div
                class="bg-purple-600 h-full rounded-full transition-all duration-500 ease-out"
                style="width: {getProgressBarWidth(country.visits, maxVisits)}%"
              ></div>
            </div>
            <div
              class="flex flex-wrap items-center space-x-3 sm:space-x-4 text-sm sm:w-1/2 sm:justify-end"
            >
              <span
                class="font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded"
              >
                {country.visits.toLocaleString()} visits
              </span>
              <span
                class="font-semibold text-green-600 bg-green-100 px-2 py-1 rounded"
              >
                {country.clicks.toLocaleString()} clicks
              </span>
              <span
                class="font-semibold text-purple-600 bg-purple-100 px-2 py-1 rounded"
              >
                {country.clickRate}% click rate
              </span>
            </div>
          </div>
        </div>
      {/if}
    {/each}
  </div>
  {#if locations.length === 0}
    <p class="text-center text-gray-500 mt-4">
      No location data available yet.
    </p>
  {/if}
</div>

<style>
  /* Add any additional styles here if needed */
</style>
