export const notify = ({
  message,
  type = "default",
  position = "top-right",
  duration = 3000,
}) => {
  // Remove existing notification if any
  const existing = document.getElementById("notification-container");

  if (existing) {
    existing.remove();
  }

  // Create container
  const container = document.createElement("div");
  container.id = "notification-container";
  container.style.position = "fixed";
  container.style.zIndex = "50";
  container.style.top = "1rem";
  container.style.right = "1rem";
  container.style.left = "auto"; // Change to auto for desktop
  container.style.width = "100%"; // Set width to 100% for mobile
  container.style.maxWidth = "30rem"; // Increased max-width for desktop

  // Create notification element
  const notification = document.createElement("div");
  notification.style.display = "flex";
  notification.style.alignItems = "center";
  notification.style.width = "100%";
  notification.style.borderRadius = "0.375rem";
  notification.style.padding = "0.875rem";
  notification.style.boxShadow =
    "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)";
  notification.style.opacity = "0";
  notification.style.transition = "opacity 0.3s ease-out";

  // Define styles based on type
  let bgColor, textColor, iconColor, progressColor, borderColor;
  switch (type) {
    case "success":
      bgColor = "#F7FDF9";
      textColor = "#166534";
      iconColor = "#22C55E";
      progressColor = "#22C55E";
      borderColor = "#22C55E";
      break;
    case "error":
      bgColor = "#FEF5F5";
      textColor = "#991B1B";
      iconColor = "#EF4444";
      progressColor = "#EF4444";
      borderColor = "#EF4444";
      break;
    case "default":
    default:
      bgColor = "#EAF5FE";
      textColor = "#0C4A6E";
      iconColor = "#3B82F6";
      progressColor = "#1E40AF";
      borderColor = "#3B82F6";
      break;
  }

  notification.style.backgroundColor = bgColor;
  notification.style.color = textColor;
  notification.style.border = `1px solid ${borderColor}`;

  // Icon
  const iconContainer = document.createElement("div");
  iconContainer.style.flexShrink = "0";
  iconContainer.style.display = "flex";
  iconContainer.style.alignItems = "center";

  const icon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  icon.setAttribute("viewBox", "0 0 20 20");
  icon.setAttribute("fill", "currentColor");
  icon.setAttribute("aria-hidden", "true");
  icon.style.width = "1.25rem";
  icon.style.height = "1.25rem";
  icon.style.color = iconColor;

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("fill-rule", "evenodd");
  if (type === "error") {
    path.setAttribute(
      "d",
      "M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
    );
  } else {
    path.setAttribute(
      "d",
      "M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
    );
  }
  path.setAttribute("clip-rule", "evenodd");

  icon.appendChild(path);
  iconContainer.appendChild(icon);

  // Message
  const messageContainer = document.createElement("div");
  messageContainer.style.marginLeft = "1rem";
  messageContainer.style.flex = "1";

  const messageText = document.createElement("p");
  messageText.style.fontSize = "0.875rem";
  messageText.style.fontWeight = "500";
  messageText.innerText = message;

  messageContainer.appendChild(messageText);

  // Close button
  const closeButtonContainer = document.createElement("div");
  closeButtonContainer.style.marginLeft = "auto";
  closeButtonContainer.style.paddingLeft = "0.75rem";
  closeButtonContainer.style.display = "flex";
  closeButtonContainer.style.alignItems = "center";

  const closeButton = document.createElement("button");
  closeButton.style.display = "inline-flex";
  closeButton.style.alignItems = "center";
  closeButton.style.justifyContent = "center";
  closeButton.style.borderRadius = "0.375rem";
  closeButton.style.padding = "0.375rem";
  closeButton.style.color = iconColor;
  closeButton.style.backgroundColor = "transparent";
  closeButton.style.border = "none";
  closeButton.style.cursor = "pointer";
  closeButton.style.fontWeight = "bold";

  const closeIcon = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "svg"
  );
  closeIcon.setAttribute("viewBox", "0 0 20 20");
  closeIcon.setAttribute("fill", "currentColor");
  closeIcon.setAttribute("aria-hidden", "true");
  closeIcon.style.width = "1.25rem";
  closeIcon.style.height = "1.25rem";

  const closePath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path"
  );
  closePath.setAttribute(
    "d",
    "M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
  );

  closeIcon.appendChild(closePath);
  closeButton.appendChild(closeIcon);
  closeButtonContainer.appendChild(closeButton);

  // Progress bar
  const progressBar = document.createElement("div");
  progressBar.style.position = "absolute";
  progressBar.style.bottom = "0";
  progressBar.style.left = "0";
  progressBar.style.width = "0%";
  progressBar.style.height = "3px";
  progressBar.style.backgroundColor = progressColor;
  progressBar.style.transition = "width linear";

  // Assemble notification
  notification.appendChild(iconContainer);
  notification.appendChild(messageContainer);
  notification.appendChild(closeButtonContainer);
  notification.appendChild(progressBar);
  container.appendChild(notification);
  document.body.appendChild(container);

  // Animation for appear
  requestAnimationFrame(() => {
    notification.style.opacity = "1";
    progressBar.style.width = "100%";
    progressBar.style.transition = `width ${duration}ms linear`;
  });

  // Function to animate out and remove
  const animateOut = () => {
    notification.style.opacity = "0";
    setTimeout(() => {
      container.remove();
    }, 300);
  };

  // Set up close button functionality
  closeButton.onclick = animateOut;

  // Auto-remove after duration
  setTimeout(animateOut, duration);

  // Add media query for mobile devices
  const mobileMediaQuery = window.matchMedia("(max-width: 640px)");
  const handleMobileChange = (e) => {
    if (e.matches) {
      container.style.left = "1rem";
      container.style.right = "1rem";
      container.style.width = "auto";
      container.style.maxWidth = "none";
    } else {
      container.style.left = "auto";
      container.style.right = "1rem";
      container.style.width = "100%";
      container.style.maxWidth = "30rem"; // Increased max-width for desktop
    }
  };
  mobileMediaQuery.addListener(handleMobileChange);
  handleMobileChange(mobileMediaQuery);
};
