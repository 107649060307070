<script>
  import { createEventDispatcher } from "svelte";
  import { notify } from "$utils/notify";
  import { cropper } from "$utils/cropper";
  import { showUploader } from "$utils/upload";

  import Button from "$components/Button.svelte";
  import Input from "$components/Input.svelte";
  import Form from "$components/Form.svelte";

  import Ajax from "$utils/ajax";

  const dispatch = createEventDispatcher();

  export let block = null;

  let url = block?.url || "";
  let title = block?.title || "";
  let imageUrl = block?.image_url || "";
  let saving = false;
  let fileInput;
  let isUploading = false;

  let errors = {};

  const saveLink = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    let sharedData = { url, block_type: "link" };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, {
          ...sharedData,
          title,
          image_url: imageUrl,
        })
      : await Ajax.post("/dashboard/blocks", sharedData);

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      if (!block) {
        url = "";
      }

      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };

  function clearImage() {
    imageUrl = "";
  }

  async function handleFileSelected() {
    isUploading = true;

    try {
      const uploadedUrl = await showUploader({
        fileType: "image",
        crop: cropper.crop,
      });
      imageUrl = uploadedUrl;
    } catch (error) {
      notify({
        message: "Upload failed. Please try again.",
        type: "error",
      });
    } finally {
      isUploading = false;
    }
  }
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit" : "Add"} Link
</h2>

<Form on:submit={saveLink}>
  <div class="flex flex-col gap-8">
    <div class="flex flex-col">
      <label for="url" class="mb-2 text-sm font-medium text-gray-700">URL</label
      >
      <Input
        id="url"
        bind:value={url}
        type="url"
        name="url"
        placeholder="Enter URL"
        required
        klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
        error={errors.url}
      />
    </div>
    {#if block}
      <div class="flex flex-col">
        <label for="title" class="mb-2 text-sm font-medium text-gray-700"
          >Title</label
        >
        <Input
          id="title"
          bind:value={title}
          type="text"
          name="title"
          placeholder="Enter Title"
          required
          klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
          error={errors.title}
        />
      </div>
      <div class="flex flex-col space-y-4">
        <div class="flex items-center space-x-4">
          {#if imageUrl}
            <div class="relative">
              <img
                src={imageUrl}
                alt="Preview"
                class="w-24 h-24 object-cover rounded-lg border border-gray-300"
              />
              <button
                type="button"
                on:click={clearImage}
                class="absolute -top-2 -right-2 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 transition-colors duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          {:else}
            <div
              class="w-24 h-24 bg-gray-100 rounded-lg border border-gray-300 flex items-center justify-center text-gray-400"
            >
              No image
            </div>
          {/if}
          <div class="flex flex-col space-y-2">
            <Button
              type="button"
              on:click={handleFileSelected}
              klass="h-8 px-3 text-xs"
              disabled={isUploading}
            >
              {imageUrl ? "Change" : "Upload"} Image
            </Button>
          </div>
        </div>
      </div>
    {/if}
    <Button type="submit" klass="w-full h-12" disabled={saving || isUploading}>
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update" : "Add"} Link
    </Button>
  </div>
</Form>
