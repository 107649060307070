<script>
  import { createEventDispatcher } from "svelte";
  import Actions from "$app-components/blocks/Actions.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block");
  }
</script>

<div class="flex flex-col bg-white rounded-lg shadow">
  <div class="flex items-center justify-between p-4">
    <div class="flex items-center space-x-3 flex-grow overflow-hidden">
      <div class="cursor-move">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>
      {#if block.image_url}
        <img
          src={block.image_url}
          alt={block.title}
          class="w-12 h-12 rounded-md object-cover flex-shrink-0"
        />
      {:else}
        <div
          class="w-12 h-12 rounded-md bg-gray-200 mr-4 flex items-center justify-center flex-shrink-0"
        >
          <span class="text-xl font-bold text-gray-400"
            >{block?.title?.[0]}</span
          >
        </div>
      {/if}
      <div class="flex-1 min-w-0 pl-2">
        <span class="text-lg font-semibold text-gray-900 truncate block"
          >{block.title}</span
        >
        <a
          href={block.url}
          target="_blank"
          rel="noopener noreferrer"
          class="text-sm text-gray-500 hover:text-gray-700 truncate block"
        >
          {block.url}
        </a>
      </div>
    </div>
    <Actions {block} {updater} on:delete-block={handleDelete} />
  </div>
</div>
