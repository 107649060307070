import LinkView from "$app-components/blocks/link/View.svelte";
import LinkAddEdit from "$app-components/blocks/link/AddEdit.svelte";

import HeadingView from "$app-components/blocks/heading/View.svelte";
import HeadingAddEdit from "$app-components/blocks/heading/AddEdit.svelte";

import ImageView from "$app-components/blocks/image/View.svelte";
import ImageAddEdit from "$app-components/blocks/image/AddEdit.svelte";

import YoutubeView from "$app-components/blocks/youtube/View.svelte";
import YoutubeAddEdit from "$app-components/blocks/youtube/AddEdit.svelte";

import TextView from "$app-components/blocks/text/View.svelte";
import TextAddEdit from "$app-components/blocks/text/AddEdit.svelte";

import ProductView from "$app-components/blocks/product/View.svelte";
import ProductAddEdit from "$app-components/blocks/product/AddEdit.svelte";

import CalendlyView from "$app-components/blocks/calendly/View.svelte";
import CalendlyAddEdit from "$app-components/blocks/calendly/AddEdit.svelte";

import VideoView from "$app-components/blocks/video/View.svelte";
import VideoAddEdit from "$app-components/blocks/video/AddEdit.svelte";

export default {
  link: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
          </svg>`,
    label: "Link",
    component: LinkView,
    addEditComponent: LinkAddEdit,
  },
  heading: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
          </svg>`,
    label: "Heading",
    component: HeadingView,
    addEditComponent: HeadingAddEdit,
  },
  image: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>`,
    label: "Image",
    component: ImageView,
    addEditComponent: ImageAddEdit,
  },
  youtube: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>`,
    label: "YouTube",
    component: YoutubeView,
    addEditComponent: YoutubeAddEdit,
  },
  text: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>`,
    label: "Text",
    component: TextView,
    addEditComponent: TextAddEdit,
  },
  product: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
          </svg>`,
    label: "Product",
    component: ProductView,
    addEditComponent: ProductAddEdit,
  },
  calendly: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>`,
    label: "Calendly",
    component: CalendlyView,
    addEditComponent: CalendlyAddEdit,
  },
  video: {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
          </svg>`,
    label: "Video",
    component: VideoView,
    addEditComponent: VideoAddEdit,
  },
};
