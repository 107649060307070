<script>
  import { onMount, createEventDispatcher } from "svelte";

  import HeadingBlock from "$app-components/blocks/heading/Page.svelte";
  import LinkBlock from "$app-components/blocks/link/Page.svelte";
  import ProductBlock from "$app-components/blocks/product/Page.svelte";
  import TextBlock from "$app-components/blocks/text/Page.svelte";
  import YoutubeBlock from "$app-components/blocks/youtube/Page.svelte";
  import ImageBlock from "$app-components/blocks/image/Page.svelte";
  import CalendlyBlock from "$app-components/blocks/calendly/Page.svelte";
  import VideoBlock from "$app-components/blocks/video/Page.svelte";

  import GridLayout from "$app-components/layouts/Grid.svelte";
  import ListLayout from "$app-components/layouts/List.svelte";

  const SLOTS = $$slots;
  const dispatch = createEventDispatcher();

  export let blocks;
  export let theme;
  export let hoverEffect = "shadow";
  export let buttonStyle = "default";
  export let klass;
  export let layoutStyle = "list";
  export let font = "inherit";
  export let gridData = [];
  export let staticGrid = true;

  $: blocks = (blocks ?? []).filter((block) => block.enabled);

  let container;
  let mounted = false;
  let containerWidth = 0;
  let containerHeight = 0;
  let isMobile = false;

  $: if (container && mounted) {
    Object.entries(theme).forEach(([key, value]) => {
      if (key === "backgroundImage") {
        if (containerWidth >= 768 && containerHeight >= 500) {
          container.style.backgroundImage = value;
          container.style.backgroundSize = "cover";
          container.style.backgroundPosition = "center";
          container.style.backgroundRepeat = "no-repeat";
          container.style.backgroundAttachment = "fixed";
        } else {
          container.style.backgroundImage = value;
          container.style.backgroundSize = "cover";
          container.style.backgroundPosition = "center";
          container.style.backgroundRepeat = "no-repeat";
          container.style.backgroundAttachment = "scroll";
        }
      } else if (key === "backgroundColor") {
        container.style.backgroundColor = value;
        container.style.backgroundImage = "none";
      } else {
        container.style.setProperty(`--${key}`, value);
      }
    });
  }

  function getBlockComponent(block) {
    if (block.block_type === "youtube") {
      return YoutubeBlock;
    } else if (block.block_type === "link") {
      return LinkBlock;
    } else if (block.block_type === "image") {
      return ImageBlock;
    } else if (block.block_type === "heading") {
      return HeadingBlock;
    } else if (block.block_type === "text") {
      return TextBlock;
    } else if (block.block_type === "product") {
      return ProductBlock;
    } else if (block.block_type === "calendly") {
      return CalendlyBlock;
    } else if (block.block_type === "video") {
      return VideoBlock;
    } else {
      return null;
    }
  }

  function handleResize() {
    if (container) {
      containerWidth = container.offsetWidth;
      containerHeight = container.offsetHeight;
      isMobile = containerWidth < 810;
    }
  }

  function handleBlockClick(e) {
    dispatch("block-click", e.detail);
  }

  function handleGridDataUpdated(e) {
    dispatch("grid-data-updated", e.detail);
  }

  onMount(() => {
    mounted = true;

    if (font !== "inherit") {
      const link = document.createElement("link");
      link.href = `https://fonts.googleapis.com/css?family=${encodeURIComponent(font)}:400,700`;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
</script>

<div
  bind:this={container}
  class="w-full h-full overflow-auto relative overflow-x-hidden {klass}"
  style="
    background-color: var(--backgroundColor);
    color: var(--textColor);
    font-family: {font}, sans-serif;
  "
>
  <div class="mx-auto py-0 px-4 relative z-1">
    {#if SLOTS.header}
      <slot name="header" />
    {/if}

    {#if !isMobile && layoutStyle === "grid"}
      <GridLayout
        {blocks}
        {theme}
        {hoverEffect}
        {buttonStyle}
        {getBlockComponent}
        {font}
        {gridData}
        {staticGrid}
        on:block-click={handleBlockClick}
        on:grid-data-updated={handleGridDataUpdated}
      />
    {:else}
      <ListLayout
        {blocks}
        {theme}
        {hoverEffect}
        {buttonStyle}
        {getBlockComponent}
        {font}
        on:block-click={handleBlockClick}
      />
    {/if}

    {#if SLOTS.footer}
      <slot name="footer" />
    {/if}
  </div>
</div>
