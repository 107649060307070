export default {
  facebook: {
    icon: "fa-brands fa-facebook-f",
    baseUrl: "https://facebook.com/",
    color: "#1877F2",
  },
  twitter: {
    icon: "fa-brands fa-x-twitter",
    baseUrl: "https://twitter.com/",
    color: "#1DA1F2",
  },
  instagram: {
    icon: "fa-brands fa-instagram",
    baseUrl: "https://instagram.com/",
    color: "#E4405F",
  },
  linkedin: {
    icon: "fa-brands fa-linkedin-in",
    baseUrl: "https://linkedin.com/in/",
    color: "#0A66C2",
  },
  github: {
    icon: "fa-brands fa-github-alt",
    baseUrl: "https://github.com/",
    color: "#181717",
  },
  youtube: {
    icon: "fa-brands fa-youtube",
    baseUrl: "https://youtube.com/",
    color: "#FF0000",
  },
  tiktok: {
    icon: "fa-brands fa-tiktok",
    baseUrl: "https://tiktok.com/@",
    color: "#000000",
  },
  pinterest: {
    icon: "fa-brands fa-pinterest",
    baseUrl: "https://pinterest.com/",
    color: "#BD081C",
  },
  snapchat: {
    icon: "fa-brands fa-snapchat",
    baseUrl: "https://snapchat.com/add/",
    color: "#FFFC00",
  },
  reddit: {
    icon: "fa-brands fa-reddit-alien",
    baseUrl: "https://reddit.com/user/",
    color: "#FF4500",
  },
  whatsapp: {
    icon: "fa-brands fa-whatsapp",
    baseUrl: "https://wa.me/",
    color: "#25D366",
  },
  telegram: {
    icon: "fa-brands fa-telegram",
    baseUrl: "https://t.me/",
    color: "#0088cc",
  },
  medium: {
    icon: "fa-brands fa-medium",
    baseUrl: "https://medium.com/@",
    color: "#00AB6C",
  },
  dribbble: {
    icon: "fa-brands fa-dribbble",
    baseUrl: "https://dribbble.com/",
    color: "#EA4C89",
  },
  behance: {
    icon: "fa-brands fa-behance",
    baseUrl: "https://www.behance.net/",
    color: "#1769FF",
  },
  vimeo: {
    icon: "fa-brands fa-vimeo",
    baseUrl: "https://vimeo.com/",
    color: "#1AB7EA",
  },
  twitch: {
    icon: "fa-brands fa-twitch",
    baseUrl: "https://www.twitch.tv/",
    color: "#9146FF",
  },
  soundcloud: {
    icon: "fa-brands fa-soundcloud",
    baseUrl: "https://soundcloud.com/",
    color: "#FF3300",
  },
  spotify: {
    icon: "fa-brands fa-spotify",
    baseUrl: "https://open.spotify.com/user/",
    color: "#1DB954",
  },
  tumblr: {
    icon: "fa-brands fa-tumblr",
    baseUrl: "https://www.tumblr.com/",
    color: "#35465C",
  },
  flickr: {
    icon: "fa-brands fa-flickr",
    baseUrl: "https://www.flickr.com/people/",
    color: "#0063DC",
  },
  quora: {
    icon: "fa-brands fa-quora",
    baseUrl: "https://www.quora.com/profile/",
    color: "#B92B27",
  },
  vk: {
    icon: "fa-brands fa-vk",
    baseUrl: "https://vk.com/",
    color: "#4A76A8",
  },
  weibo: {
    icon: "fa-brands fa-weibo",
    baseUrl: "https://weibo.com/",
    color: "#DF2029",
  },
  line: {
    icon: "fa-brands fa-line",
    baseUrl: "https://line.me/R/ti/p/",
    color: "#00C300",
  },
  discord: {
    icon: "fa-brands fa-discord",
    baseUrl: "https://discord.com/users/",
    color: "#7289DA",
  },
  stackoverflow: {
    icon: "fa-brands fa-stack-overflow",
    baseUrl: "https://stackoverflow.com/users/",
    color: "#F48024",
  },
  deviantart: {
    icon: "fa-brands fa-deviantart",
    baseUrl: "https://www.deviantart.com/",
    color: "#05CC47",
  },
  goodreads: {
    icon: "fa-brands fa-goodreads",
    baseUrl: "https://www.goodreads.com/user/show/",
    color: "#553B08",
  },
};
