<script>
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { GridStack } from "gridstack";

  import "gridstack/dist/gridstack.min.css";
  import "gridstack/dist/gridstack-extra.min.css";

  const dispatch = createEventDispatcher();

  export let blocks;
  export let theme;
  export let hoverEffect;
  export let buttonStyle;
  export let getBlockComponent;
  export let maxColumns = 3;

  let gridElement;
  let nodes = [];
  let maxSize = 1152;

  let isFirstBlockHeading = blocks?.[0]?.block_type === "heading";

  function addedElement(node) {
    nodes.push(node);
  }

  function setupGridStack() {
    let sections = [];
    let currentSection = [];

    blocks.forEach((block, index) => {
      const isHeading = block.block_type === "heading";
      if (isHeading && currentSection.length > 0) {
        sections.push(currentSection);
        currentSection = [];
      }
      currentSection.push({ block, node: nodes[index] });
    });

    if (currentSection.length > 0) {
      sections.push(currentSection);
    }

    sections.forEach((section) => {
      const gridDiv = document.createElement("div");
      gridDiv.className = "grid-stack";
      gridDiv.style.width = "100%";
      gridDiv.style.maxWidth = "100%";
      gridElement.appendChild(gridDiv);

      const gridItems = section.map(({ block, node }, index) => {
        const isHeading = block.block_type === "heading";
        const height = isHeading
          ? node.offsetHeight + 70
          : node.offsetHeight + 16;

        if (isHeading) {
          node.style.paddingTop = "40px";
        }

        return {
          el: node,
          w: isHeading ? maxColumns : 1,
          h: height,
          id: block.id,
          content: block,
        };
      });

      const grid = GridStack.init(
        {
          column: maxColumns,
          disableResize: true,
          cellHeight: 1,
          staticGrid: true,
        },
        gridDiv
      );

      if (gridItems.length === 2) {
        gridDiv.style.marginLeft = "192px";
      } else if (gridItems.length === 1) {
        gridDiv.style.marginLeft = "384px";
      }

      grid.load(gridItems);
    });

    nodes.forEach((node) => {
      node.style.removeProperty("max-width");
    });
  }

  onMount(async () => {
    await tick();

    // Wait for the next frame to ensure all blocks are rendered
    requestAnimationFrame(async () => {
      await tick();
      setupGridStack();
    });
  });
</script>

<div class="mt-10">
  <div
    class="grid-container layout-container mx-auto"
    style="width: 100%; max-width: {maxSize}px; {isFirstBlockHeading
      ? 'margin-top: -56px'
      : ''}"
    bind:this={gridElement}
  >
    {#each blocks as block, index}
      <div
        use:addedElement
        style="padding-left: 8px; padding-right: 8px; max-width: {block.block_type ===
        'heading'
          ? maxSize
          : maxSize / maxColumns}px;"
        class="grid-stack-item"
        data-block-id={block.id}
      >
        <svelte:component
          this={getBlockComponent(block)}
          {block}
          {theme}
          {index}
          {buttonStyle}
          {hoverEffect}
          on:block-click={(e) => dispatch("block-click", e.detail)}
        />
      </div>
    {/each}
  </div>
</div>
