<script>
  import { onMount } from "svelte";
  import Toggle from "$components/Toggle.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let currentUser;
  export let updater;

  let subscriptionsEnabled = currentUser.subscriptions_enabled;
  let saving = false;
  let subscribeLink = currentUser.url;

  async function toggleSubscriptions() {
    saving = true;
    const [error, response] = await Ajax.post(
      "/dashboard/settings/save_subscription",
      {
        subscriptions_enabled: subscriptionsEnabled,
      }
    );

    if (error) {
      notify({
        message: "Failed to update subscription settings",
        type: "error",
      });

      subscriptionsEnabled = !subscriptionsEnabled; // Revert the toggle
    } else {
      notify({
        message: "Subscription settings updated successfully",
        type: "success",
      });

      updater(({ currentUser }) => ({
        currentUser: {
          ...currentUser,
          subscriptions_enabled: subscriptionsEnabled,
        },
      }));
    }
    saving = false;
  }

  function copySubscribeLink() {
    navigator.clipboard.writeText(subscribeLink);
    notify({
      message: "Subscribe link copied to clipboard!",
      type: "success",
    });
  }

  onMount(() => {
    subscriptionsEnabled = currentUser.subscriptions_enabled;
  });
</script>

<div class="ml-0">
  <div class="bg-white shadow rounded-lg p-6">
    <h1 class="text-xl font-bold mb-12">Subscription</h1>

    <div class="flex items-center justify-between mb-4">
      <div>
        <h2 class="text-lg font-semibold">Allow Visitors to Subscribe</h2>
        <p class="text-gray-600 mt-1">
          Enable this option to let visitors subscribe to your updates
        </p>
      </div>
      <Toggle
        bind:checked={subscriptionsEnabled}
        on:change={toggleSubscriptions}
        disabled={saving}
      />
    </div>

    <p class="text-sm text-gray-500 mt-6 leading-relaxed">
      When enabled, a subscription form will be displayed on your profile,
      allowing visitors to sign up for updates. This can help you build an
      audience and keep them engaged with your content.
    </p>

    {#if subscriptionsEnabled}
      <div class="mt-12 p-4 bg-purple-50 rounded-lg border border-purple-200">
        <h3 class="text-lg font-semibold text-purple-700 mb-2">
          Share Your Subscribe Link
        </h3>
        <p class="text-sm text-purple-600 mb-4">
          Share this link to let your audience know they can subscribe:
        </p>
        <div class="flex items-center">
          <input
            type="text"
            readonly
            value={subscribeLink}
            class="flex-grow block w-full rounded-l-md border border-purple-300 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
          <button
            on:click={copySubscribeLink}
            class="px-4 py-2 bg-purple-600 text-white rounded-r-md hover:bg-purple-700 focus:outline-none transition duration-150 ease-in-out"
          >
            Copy
          </button>
        </div>
      </div>
    {/if}
  </div>
</div>
