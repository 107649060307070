<script>
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";
  import { createEventDispatcher, onMount } from "svelte";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;

  let transitionDuration = "200ms";

  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, transitionDuration);
  $: blockRadius = getBorderRadius(buttonStyle);

  const dispatch = createEventDispatcher();

  function handleClick() {
    dispatch("block-click", { block_id: block.id });
    setTimeout(() => (location.href = block.url), 50);
  }

  onMount(() => {
    dispatch("mounted");
  });
</script>

<div
  class="overflow-hidden transition-all ease-in-out w-full"
  style={currentButtonStyle}
  use:useHoverEffect={{
    theme,
    buttonStyle,
    hoverEffect,
    transitionDuration,
    getBlockStyle,
  }}
>
  <a
    href={block.url}
    target="_blank"
    rel="noopener noreferrer"
    on:click|preventDefault={handleClick}
    class="flex items-center p-4 transition-colors ease-in-out"
    style="
      color: {buttonStyle && buttonStyle.startsWith('fill')
      ? 'var(--buttonTextColor)'
      : 'var(--linkColor)'};
      transition-duration: {transitionDuration};
    "
  >
    {#if block.image_url}
      <img
        src={block.image_url}
        alt={block.title}
        class="w-12 h-12 object-cover mr-4 flex-shrink-0"
        style="border-radius: {blockRadius}"
      />
    {:else}
      <div
        class="w-12 h-12 mr-4 flex items-center justify-center flex-shrink-0"
        style="
          background-color: var(--imagePlaceholderColor);
          border-radius: {blockRadius};
        "
      >
        <span
          style="
          color: var(--imagePlaceholderTextColor);
          font-weight: var(--titleFontWeight);
        ">{block?.title?.[0]}</span
        >
      </div>
    {/if}
    <div class="flex-grow">
      <h2
        class="line-clamp-2"
        style="
        font-weight: var(--titleFontWeight);
        font-size: var(--titleFontSize);
      "
      >
        {block.title}
      </h2>
    </div>
  </a>
</div>
