<script>
  import { onMount, afterUpdate } from "svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let initialSelectedFont = "Roboto"; // Allow passing in a selected font

  let fonts = [];
  let filteredFonts = [];
  let selectedFont = initialSelectedFont;
  let selectedFontType = "all";
  let searchQuery = "";
  let observer;
  let fontTypes = [
    "all",
    "serif",
    "sans-serif",
    "display",
    "handwriting",
    "monospace",
  ];

  let loadingFonts = new Set();
  let isMobile = false;

  onMount(async () => {
    const response = await fetch(
      "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBiFT5vZnPialAigP2741H_J_guzRSPiZw"
    );
    const data = await response.json();
    fonts = data.items;
    filteredFonts = fonts;

    observer = new IntersectionObserver(handleIntersection, {
      root: document.querySelector("#font-picker"),
      rootMargin: "200px 0px", // Load fonts 200px above and below the viewport
      threshold: 0.1,
    });

    document.querySelectorAll(".font-option").forEach((option) => {
      observer.observe(option);
    });

    // Set the selected font by default
    if (fonts.length > 0) {
      const defaultFont = fonts.find((font) => font.family === selectedFont);
      if (defaultFont) {
        selectedFont = defaultFont.family;
      } else {
        selectedFont = fonts[0].family;
      }
      dispatch("font-change", selectedFont);
    }

    // Check if the device is mobile
    isMobile = window.innerWidth <= 480;
    window.addEventListener("resize", () => {
      isMobile = window.innerWidth <= 480;
    });
  });

  function handleIntersection(entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        const font = entry.target.getAttribute("data-font-family");
        if (font !== "inherit" && !loadingFonts.has(font)) {
          if (!window.loadedFonts) {
            window.loadedFonts = new Set();
          }
          if (!window.loadedFonts.has(font)) {
            const link = document.createElement("link");
            link.href = `https://fonts.googleapis.com/css?family=${encodeURIComponent(font)}`;
            link.rel = "stylesheet";
            link.onload = () => {
              entry.target.style.fontFamily = font;
              loadingFonts.delete(font);
              loadingFonts = loadingFonts;
              window.loadedFonts.add(font);
            };
            document.head.appendChild(link);
            loadingFonts.add(font);
          } else {
            entry.target.style.fontFamily = font;
          }
        }
      }
    });
  }

  function handleFontChange(font) {
    selectedFont = font;
    dispatch("font-change", selectedFont);
  }

  function handleFontTypeChange(event) {
    selectedFontType = isMobile ? event.target.value : event;
    filterFonts();
    scrollToTop();
  }

  function handleSearchQueryChange(event) {
    searchQuery = event.target.value;
    filterFonts();
  }

  function clearSearchQuery() {
    searchQuery = "";
    filterFonts();
  }

  function filterFonts() {
    filteredFonts = fonts.filter((font) => {
      const matchesType =
        selectedFontType === "all" || font.category === selectedFontType;
      const matchesQuery = font.family
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      return matchesType && matchesQuery;
    });
  }

  function scrollToTop() {
    const fontPicker = document.querySelector("#font-picker");
    if (fontPicker) {
      fontPicker.scrollTop = 0;
    }
  }

  afterUpdate(() => {
    if (observer) {
      document.querySelectorAll(".font-option").forEach((option) => {
        observer.observe(option);
      });
    }
  });
</script>

<style>
  .font-picker-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .font-type-selector {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .font-type-label {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
  }

  .selected-font {
    font-size: 1rem;
    font-weight: bold;
    color: rgb(147 51 234);
    margin-left: 8px;
    word-break: break-word;
  }

  .search-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
    gap: 8px;
  }

  .search-container input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 8px;
    min-width: 0;
  }

  .clear-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.1s;
  }

  .clear-button:hover {
    background-color: #e0e0e0;
  }

  .font-picker {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px;
    background-color: #fff;
  }

  .font-option {
    padding: 12px;
    cursor: pointer;
    transition:
      background-color 0.1s,
      transform 0.05s;
    border-radius: 4px;
  }

  .font-option.selected {
    background-color: #f0f0f0;
  }

  .font-option:hover {
    background-color: #e0e0e0;
  }

  .pill-select {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    flex-wrap: wrap;
  }

  .pill-option {
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.1s;
    font-size: 0.9rem;
  }

  .pill-option.active {
    background-color: #ddd;
    font-weight: bold;
  }

  .pill-option:hover {
    background-color: #f0f0f0;
  }

  .mobile-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    .font-picker-container {
      padding: 12px;
    }

    .font-type-label {
      font-size: 0.9rem;
    }

    .selected-font {
      font-size: 0.9rem;
    }

    .font-option {
      padding: 10px;
    }
  }
</style>

<div class="font-picker-container">
  <div class="font-type-selector">
    <label for="font-type-select" class="font-type-label">
      <span>Currently selected:</span>
      <span class="selected-font">{selectedFont}</span>
    </label>
    {#if isMobile}
      <select class="mobile-select" on:change={handleFontTypeChange}>
        {#each fontTypes as type}
          <option value={type} selected={selectedFontType === type}>
            {type}
          </option>
        {/each}
      </select>
    {:else}
      <div class="pill-select">
        {#each fontTypes as type}
          <button
            class="pill-option {selectedFontType === type ? 'active' : ''}"
            on:click={() => handleFontTypeChange(type)}>
            {type}
          </button>
        {/each}
      </div>
    {/if}
  </div>
  <div id="font-picker" class="font-picker">
    <div class="search-container">
      <input
        type="text"
        placeholder="Search fonts..."
        value={searchQuery}
        on:input={handleSearchQueryChange} />
      {#if searchQuery}
        <button on:click={clearSearchQuery} class="clear-button">Clear</button>
      {/if}
    </div>
    {#each filteredFonts as font}
      {#if selectedFontType === "all" || font.category === selectedFontType}
        <div
          class={`font-option ${selectedFont === font.family ? "selected" : ""} p-3 cursor-pointer transition-all duration-75 transform rounded-md ${selectedFont === font.family ? "bg-gray-200" : ""}`}
          style="font-family: sans-serif;"
          on:click={() => handleFontChange(font.family)}
          data-font-family={font.family}>
          {font.family}
        </div>
      {/if}
    {/each}
  </div>
</div>
