<script>
  import { onMount } from "svelte";
  import { fade, fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { sendEvent } from "$utils/event";
  import { getThemeFromId } from "$utils/themes";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  export let profileUser;

  let showModal = false;
  let email = "";
  let isSubmitting = false;
  let subscriptionSuccess = false;
  let buttonLeft = 0;
  let theme = getThemeFromId(profileUser.theme).theme;
  let userFont = profileUser.font;
  let subscribeButton;

  function toggleModal() {
    showModal = !showModal;
    if (showModal) {
      sendEvent("subscribe_button:click", { profile_user_id: profileUser.id });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!email || isSubmitting) return;
    isSubmitting = true;

    const data = {
      email: email,
    };

    const [ajaxErrors, resp] = await Ajax.post(
      `/page/${profileUser.username}/subscribe`,
      data
    );

    if (ajaxErrors) {
      notify({
        message: "Failed to subscribe. Please try again.",
        type: "error",
      });
    } else {
      subscriptionSuccess = true;
      sendEvent("subscribe_button:submit", {
        profile_user_id: profileUser.id,
      });
    }

    isSubmitting = false;

    setTimeout(() => {
      showModal = false;
      subscriptionSuccess = false;
      email = "";
    }, 3000);
  }

  function updateButtonPosition() {
    const firstBlockComponent = document.querySelector(".layout-container");
    if (firstBlockComponent) {
      const rect = firstBlockComponent.getBoundingClientRect();
      buttonLeft = rect.left;
    }
  }

  function handleScroll() {
    if (subscribeButton) {
      if (window.scrollY > 150) {
        subscribeButton.classList.add("sb-hidden");
      } else {
        subscribeButton.classList.remove("sb-hidden");
      }
    }
  }

  onMount(() => {
    updateButtonPosition();
    window.addEventListener("resize", updateButtonPosition);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", updateButtonPosition);
      window.removeEventListener("scroll", handleScroll);
    };
  });
</script>

<style>
  .subscribe-button {
    width: 40px;
    transition:
      width 0.3s ease-in-out,
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    transform: translateY(0);
  }

  :global(.subscribe-button.sb-hidden) {
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    transition:
      opacity 0.1s ease-out,
      transform 0.1s ease-out;
  }

  .subscribe-button:hover {
    opacity: 1;
  }

  .subscribe-text {
    opacity: 0;
    width: 0;
    transition:
      opacity 0.3s ease-in-out,
      width 0.3s ease-in-out;
  }

  @keyframes subscribeAnimation {
    0%,
    100% {
      width: 40px;
    }
    20%,
    80% {
      width: 140px;
    }
  }

  @keyframes textAnimation {
    0%,
    100% {
      opacity: 0;
      width: 0;
    }
    20%,
    80% {
      opacity: 1;
      width: auto;
    }
  }

  .subscribe-button {
    animation: subscribeAnimation 3s ease-in-out 0.2s forwards;
  }

  .subscribe-text {
    animation: textAnimation 3s ease-in-out 0.2s forwards;
  }

  .modal-overlay {
    backdrop-filter: blur(5px);
  }

  .modal-content {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }

  .input-wrapper {
    position: relative;
  }

  .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .subscribe-input {
    padding-left: 40px;
  }

  .subscribe-btn {
    transition: all 0.3s ease;
  }

  .subscribe-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
</style>

<button
  bind:this={subscribeButton}
  on:click={toggleModal}
  class="fixed top-4 z-2 text-white p-2 rounded-full transition-all duration-300 ease-in-out focus:outline-none flex items-center overflow-hidden subscribe-button opacity-80 shadow-sm"
  style="left: {buttonLeft}px; background-color: {theme.cardBackgroundColor}; color: {theme.linkColor}; font-family: {userFont};">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6 flex-shrink-0"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
  </svg>
  <span class="ml-2 whitespace-nowrap subscribe-text">Subscribe</span>
</button>

{#if showModal}
  <div
    class="fixed inset-0 overflow-y-auto h-full w-full z-50 flex items-center justify-center modal-overlay"
    on:click={toggleModal}
    transition:fade={{ duration: 300 }}
    style="background-color: rgba(0, 0, 0, 0.5);">
    <div
      class="relative p-4 sm:p-8 border w-full max-w-md mx-4 sm:mx-auto modal-content"
      on:click|stopPropagation
      in:fly={{ y: 50, duration: 300, easing: quintOut }}
      out:fade
      style="background-color: white; border-radius: 12px; font-family: {userFont};">
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        on:click={toggleModal}
        aria-label="Close">
        <svg
          class="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
      <div class="text-center">
        <div
          class="mx-auto flex items-center justify-center h-20 w-20 sm:h-24 sm:w-24 rounded-full mb-4 sm:mb-8 mt-2 sm:mt-4">
          <img
            src={profileUser.profile_image_url}
            alt={profileUser.profile_name}
            class="h-16 w-16 sm:h-20 sm:w-20 rounded-full object-cover" />
        </div>
        <h3 class="text-lg sm:text-xl font-bold mb-1 text-black">
          Subscribe to {profileUser.profile_name}
        </h3>
        <p class="text-sm sm:text-base mb-6 sm:mb-10 text-gray-700">
          Stay updated with the latest content
        </p>
        {#if !subscriptionSuccess}
          <form on:submit|preventDefault={handleSubmit} class="mt-4 sm:mt-6">
            <div class="input-wrapper mb-4">
              <svg
                class="input-icon h-5 w-5"
                fill="none"
                stroke="black"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
              <input
                type="email"
                bind:value={email}
                placeholder="Enter your email"
                class="w-full px-4 py-3 rounded-lg subscribe-input text-sm sm:text-base"
                style="color: black; background-color: white; font-family: {userFont}; outline: none; box-shadow: none;"
                required />
            </div>
            <button
              type="submit"
              class="w-full font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out subscribe-btn text-sm sm:text-base bg-purple-700 text-white {isSubmitting
                ? 'opacity-50 cursor-not-allowed'
                : ''}"
              style="font-family: {userFont};"
              disabled={isSubmitting}>
              {isSubmitting ? "Subscribing..." : "Subscribe"}
            </button>
          </form>
        {:else}
          <p class="text-lg sm:text-xl font-semibold text-green-700">
            Successfully subscribed!
          </p>
        {/if}
      </div>
    </div>
  </div>
{/if}
