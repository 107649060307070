<script>
  import { createEventDispatcher } from "svelte";

  export let blocks;
  export let theme;
  export let hoverEffect;
  export let buttonStyle;
  export let getBlockComponent;

  const dispatch = createEventDispatcher();
</script>

<ul
  class="flex flex-col items-center mt-6 w-full max-w-[500px] mx-auto layout-container"
>
  {#each blocks as block, index (block)}
    <li
      class="w-full block-component"
      class:mt-8={index > 0 && block.block_type === "heading"}
      class:mb-6={block.block_type === "heading"}
      class:mb-4={block.block_type !== "heading"}
    >
      <div class="flex justify-center">
        <svelte:component
          this={getBlockComponent(block)}
          {block}
          {theme}
          {index}
          {buttonStyle}
          {hoverEffect}
          on:block-click={(e) => dispatch("block-click", e.detail)}
        />
      </div>
    </li>
  {/each}
</ul>
