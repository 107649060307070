export function useHoverEffect(
  node,
  { theme, buttonStyle, hoverEffect, transitionDuration, getBlockStyle }
) {
  let currentHoverEffect = hoverEffect;
  let currentButtonStyle = buttonStyle;

  function getHoverClass(effect) {
    switch (effect) {
      case "shadow":
        return "hover:shadow-lg transform hover:-translate-y-1 transition-all duration-100";
      case "scale":
        return "hover:scale-anim";
      case "pulse":
        return "hover:pulse-anim";
      case "shake":
        return "hover:shake-anim";
      case "fade":
        return "hover:fade-anim";
      case "color-shift":
        return "hover:color-shift-anim";
      case "glow":
        return "hover:glow-anim";
      default:
        return "";
    }
  }

  function getHoverStyle(style, effect) {
    if (effect === "shadow") {
      switch (style) {
        case "hardshadow-square":
        case "hardshadow-rounded":
        case "hardshadow-pill":
          return `box-shadow: 6px 6px 0 0 var(--cardHoverShadowColor);`;
        default:
          return `box-shadow: 0 10px 15px -3px var(--cardHoverShadowColor), 0 4px 6px -2px var(--cardHoverShadowColor);`;
      }
    } else if (effect === "glow") {
      return `box-shadow: 0 0 10px 3px var(--cardHoverShadowColor);`;
    }
    return "";
  }

  function handleMouseEnter(e) {
    if (currentHoverEffect === "shadow" || currentHoverEffect === "glow") {
      e.currentTarget.style.cssText += getHoverStyle(
        currentButtonStyle,
        currentHoverEffect
      );
    }
  }

  function handleMouseLeave(e) {
    if (currentHoverEffect === "shadow" || currentHoverEffect === "glow") {
      e.currentTarget.style.cssText = getBlockStyle(
        theme,
        currentButtonStyle,
        transitionDuration
      );
    }
  }

  function applyHoverEffect() {
    const hoverClasses = getHoverClass(currentHoverEffect)
      .split(" ")
      .filter(Boolean);
    if (hoverClasses.length > 0) {
      node.classList.add(...hoverClasses);
    }
  }

  function removeHoverEffect() {
    const hoverClasses = getHoverClass(currentHoverEffect)
      .split(" ")
      .filter(Boolean);
    if (hoverClasses.length > 0) {
      node.classList.remove(...hoverClasses);
    }
  }

  applyHoverEffect();
  node.addEventListener("mouseenter", handleMouseEnter);
  node.addEventListener("mouseleave", handleMouseLeave);

  return {
    update(params) {
      if (
        params.hoverEffect !== currentHoverEffect ||
        params.buttonStyle !== currentButtonStyle
      ) {
        removeHoverEffect();
        currentHoverEffect = params.hoverEffect;
        currentButtonStyle = params.buttonStyle;
        applyHoverEffect();
      }
    },
    destroy() {
      node.removeEventListener("mouseenter", handleMouseEnter);
      node.removeEventListener("mouseleave", handleMouseLeave);
      removeHoverEffect();
    },
  };
}
