<script>
  import { createEventDispatcher } from "svelte";
  import { standard, minimalist, fun } from "$utils/themes";

  export let selectedTheme;

  let selectedCategory = standard.some((theme) => theme.id === selectedTheme.id)
    ? "standard"
    : minimalist.some((theme) => theme.id === selectedTheme.id)
      ? "minimalist"
      : fun.some((theme) => theme.id === selectedTheme.id)
        ? "fun"
        : "standard";

  let themes =
    selectedCategory === "standard"
      ? standard
      : selectedCategory === "minimalist"
        ? minimalist
        : selectedCategory === "fun"
          ? fun
          : standard;

  const dispatch = createEventDispatcher();

  const handleThemeChanged = (theme) => {
    selectedTheme = theme;
    dispatch("theme-changed", { theme });
  };

  const handleCategoryChanged = (category) => {
    selectedCategory = category;
    switch (category) {
      case "standard":
        themes = standard;
        break;
      case "minimalist":
        themes = minimalist;
        break;
      case "fun":
        themes = fun;
        break;
    }
  };
</script>

<div class="mb-8">
  <div class="flex border-b">
    <button
      class="py-2 px-4 {selectedCategory === 'standard'
        ? 'border-b-2 border-purple-500'
        : ''}"
      on:click={() => handleCategoryChanged("standard")}
    >
      Standard
    </button>
    <button
      class="py-2 px-4 {selectedCategory === 'minimalist'
        ? 'border-b-2 border-purple-500'
        : ''}"
      on:click={() => handleCategoryChanged("minimalist")}
    >
      Minimalist
    </button>
    <button
      class="py-2 px-4 {selectedCategory === 'fun'
        ? 'border-b-2 border-purple-500'
        : ''}"
      on:click={() => handleCategoryChanged("fun")}
    >
      Fun
    </button>
  </div>
</div>

<div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-4">
  {#each themes as theme (theme.name)}
    <div
      class="theme-card p-1 border rounded-lg cursor-pointer hover:shadow-md transition-shadow {selectedTheme ===
      theme
        ? 'border-gray-600'
        : 'border-gray-200'}"
      on:click={() => handleThemeChanged(theme)}
    >
      <div class="theme-preview mb-2 h-60 rounded overflow-hidden">
        <!-- Mini preview of the theme -->
        <div
          class="w-full h-full flex flex-col items-center p-4"
          style="background-color: {theme.theme.backgroundColor}; 
                 background-image: {theme.theme.backgroundImage}; 
                 background-repeat: no-repeat; 
                 background-size: cover; 
                 background-position: center;"
        >
          <div class="w-full flex flex-col gap-2">
            {#each [1, 2, 3] as _}
              <div
                class="w-full h-8 rounded flex items-center justify-center text-xs"
                style="background-color: {theme.theme.cardBackgroundColor}; 
                       color: {theme.theme.linkColor}; 
                       box-shadow: 0 2px 4px {theme.theme.cardShadowColor};"
              >
                Link
              </div>
            {/each}
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center">
        <span class="text-sm font-medium text-center">{theme.name}</span>
      </div>
    </div>
  {/each}
</div>
