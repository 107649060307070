<script>
  import { onMount } from "svelte";
  import { getThemeFromId } from "$utils/themes";
  import { sendEvent } from "$utils/event";

  import PageLayout from "$layouts/Page.svelte";
  import ProfileHeader from "$app-components/ProfileHeader.svelte";
  import BlockViewer from "$app-components/BlockViewer.svelte";
  import SocialLinks from "$app-components/SocialLinks.svelte";
  import SubscribeButton from "$app-components/SubscribeButton.svelte";
  import SharePage from "$app-components/SharePage.svelte";

  export let profileUser;
  export let blocks;
  export let socialLinks;

  const theme = getThemeFromId(profileUser.theme).theme;

  // Get URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const showHeader = urlParams.get("header") !== "false";
  const showFooter = urlParams.get("footer") !== "false";

  function pageViewedEvent() {
    sendEvent("profile:view", {
      profile_user_id: profileUser.id,
    });
  }

  function handleSocialLinkClick(event) {
    sendEvent("profile:social-link-click", {
      ...event.detail,
      profile_user_id: profileUser.id,
    });
  }

  onMount(() => {
    pageViewedEvent();
  });

  function handleBlockClick(event) {
    sendEvent("profile:block-click", {
      profile_user_id: profileUser.id,
      ...event.detail,
    });
  }
</script>

<PageLayout {profileUser}>
  <BlockViewer
    {blocks}
    gridData={profileUser.grid_data["grid"]}
    font={profileUser.font}
    {theme}
    buttonStyle={profileUser.button_style}
    hoverEffect={profileUser.hover_effect}
    klass="flex-grow pt-8 pb-12"
    layoutStyle={profileUser.layout_style}
    on:block-click={handleBlockClick}
  >
    <svelte:fragment slot="header">
      {#if showHeader}
        {#if profileUser.subscriptions_enabled}
          <SubscribeButton {profileUser} />
        {/if}

        {#if profileUser.share_page_enabled}
          <SharePage {profileUser} />
        {/if}

        <ProfileHeader
          klass="mb-4"
          user={{
            ...profileUser,
            profile_image_url: profileUser.profile_image_url,
            profile_name: profileUser.profile_name,
            bio: profileUser.bio,
          }}
        />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="footer">
      {#if showFooter}
        <div class="mt-12">
          <SocialLinks
            links={socialLinks}
            {theme}
            on:social-link-click={handleSocialLinkClick}
          />
        </div>
      {/if}
    </svelte:fragment>
  </BlockViewer>
</PageLayout>
