<script>
  import { createEventDispatcher } from "svelte";

  export let selectedButtonStyle;
  export let selectedHoverEffect;

  const buttonCategories = {
    default: ["default"],
    fill: ["fill-square", "fill-rounded", "fill-pill"],
    outline: ["outline-square", "outline-rounded", "outline-pill"],
    "soft shadow": [
      "softshadow-square",
      "softshadow-rounded",
      "softshadow-pill",
    ],
    "hard shadow": [
      "hardshadow-square",
      "hardshadow-rounded",
      "hardshadow-pill",
    ],
  };

  const hoverEffects = [
    "none",
    "shadow",
    "scale",
    "pulse",
    "shake",
    "fade",
    "color-shift",
    "glow",
  ];

  const dispatch = createEventDispatcher();

  function handleButtonStyleChange(style) {
    selectedButtonStyle = style;
    dispatch("button-style-changed", { style });
  }

  function handleHoverEffectChange(effect) {
    selectedHoverEffect = effect;
    dispatch("hover-effect-changed", { effect });
  }

  function getButtonClass(style) {
    const baseClass = "w-full h-10 py-2 px-4 ";

    if (style === "default") return baseClass + "rounded-md shadow-md";

    let styleClass = "";
    if (style.includes("square")) styleClass = "rounded-none";
    else if (style.includes("rounded")) styleClass = "rounded-lg";
    else if (style.includes("pill")) styleClass = "rounded-full";

    if (style.includes("fill")) styleClass += " bg-black text-white";
    else if (style.includes("outline"))
      styleClass += " border border-black text-black";
    else if (style.includes("softshadow"))
      styleClass += " bg-white text-black shadow-md";
    else if (style.includes("hardshadow"))
      styleClass +=
        " bg-white text-black border border-black shadow-[4px_4px_0_0_black]";

    return baseClass + styleClass;
  }
</script>

<div class="mb-8 bg-white rounded-lg shadow-md p-6">
  <h2 class="text-lg font-semibold mb-8">Block Style</h2>
  {#each Object.entries(buttonCategories) as [category, styles]}
    <div class="mb-4">
      <h3 class="text-md font-medium mb-2">{category}</h3>
      <div class="grid grid-cols-3 gap-4">
        {#each styles as style}
          <div class="relative p-3">
            <button
              class={getButtonClass(style)}
              on:click={() => handleButtonStyleChange(style)} />
            {#if selectedButtonStyle === style}
              <div
                class="absolute inset-0 border-2 border-purple-500 rounded-lg pointer-events-none">
              </div>
            {/if}
          </div>
        {/each}
      </div>
    </div>
  {/each}
</div>

<div class="mb-8 bg-white rounded-lg shadow-md p-6">
  <h2 class="text-lg font-semibold mb-6">Hover Effect</h2>
  <div class="grid grid-cols-2 gap-4">
    {#each hoverEffects as effect}
      <button
        class={`w-full py-3 px-4 rounded-lg transition-all duration-200 ease-in-out ${
          selectedHoverEffect === effect
            ? "bg-purple-600 text-white shadow-lg"
            : "bg-gray-100 text-gray-800 hover:bg-gray-200"
        }`}
        on:click={() => handleHoverEffectChange(effect)}>
        <span class="font-medium">{effect}</span>

        {#if selectedHoverEffect === effect}
          <span class="ml-2">✓</span>
        {/if}
      </button>
    {/each}
  </div>
</div>

<!-- Add more setting options here -->
