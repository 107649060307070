<script>
  import DefaultLayout from "$layouts/Default.svelte";

  import Form from "$components/Form.svelte";
  import Input from "$components/Input.svelte";
  import Button from "$components/Button.svelte";
  import Card from "$components/Card.svelte";
  import Link from "$components/Link.svelte";
  import Logo from "$components/Logo.svelte";

  import { goto } from "$utils/goto";
  import { notify } from "$utils/notify";
  import { validateErrors } from "$utils/form";
  import Ajax from "$utils/ajax";

  let errors = {};

  let register = async (event) => {
    const [ajaxErrors, resp] = await Ajax.post("/register", event.detail);

    if (ajaxErrors) {
      errors = validateErrors(errors, event.detail, ajaxErrors);
    } else {
      notify({ message: "Account created successfully", type: "success" });
      goto("/dashboard");
    }
  };

  let validate = async (event) => {
    let [ajaxErrors, _resp] = await Ajax.post(
      "/register/validate",
      event.detail.data
    );

    errors = validateErrors(errors, event.detail.data, ajaxErrors);
  };
</script>

<DefaultLayout>
  <div class="flex justify-center mb-16">
    <Logo />
  </div>

  <Card size="lg" klass="mx-auto max-w-sm">
    <svelte:fragment slot="title">
      <h2 class="text-2xl font-extrabold text-center mb-6">
        Register for an account
      </h2>
      <p class="text-sm text-gray-600 text-center mb-8">
        Already registered? <Link to={"/login"}>Log in</Link> to your account.
      </p>
    </svelte:fragment>

    <svelte:fragment slot="content">
      <div class="pt-2">
        <Form on:submit={register}>
          <div class="space-y-5 flex flex-col gap-2">
            <Input
              name="email"
              type="email"
              label="Email"
              error={errors.email}
              required
              on:keyup={validate}
            />
            <Input
              name="password"
              type="password"
              label="Password"
              error={errors.password}
              required
              on:keyup={validate}
            />
            <Input
              name="username"
              type="text"
              label="Username"
              error={errors.username}
              required
              on:keyup={validate}
            >
              <svelte:fragment slot="prepend">bio.ink/</svelte:fragment>
            </Input>

            <div>
              <Button
                type="submit"
                enableAnimation={true}
                disabled={Object.keys(errors).length > 0}
                klass="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md transition mt-2 duration-300"
              >
                Create an account
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </svelte:fragment>
  </Card>
</DefaultLayout>
