<script>
  export let width = "375px";
  export let height = "calc(100vh - 80px)";
  export let maxHeight = "740px";
</script>

<div class="w-full flex justify-center items-center" style="height: {height};">
  <div
    class="relative bg-gray-100 rounded-[60px] shadow-lg overflow-hidden"
    style="width: {width}; height: 100%; max-height: {maxHeight};"
  >
    <!-- Notch -->
    <div
      class="absolute top-0 left-1/2 transform -translate-x-1/2 w-[40%] h-7 bg-black rounded-b-3xl z-10"
    ></div>
    <!-- Screen -->
    <div
      class="absolute inset-3 bg-white rounded-[50px] overflow-hidden flex flex-col"
    >
      <!-- Status Bar -->
      <div class="h-6 bg-white flex justify-between items-center px-6 text-xs">
        <span>9:41</span>
        <div class="flex space-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"
            ></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M17.778 8.222c-4.296-4.296-11.26-4.296-15.556 0A1 1 0 01.808 6.808c5.076-5.077 13.308-5.077 18.384 0a1 1 0 01-1.414 1.414zM14.95 11.05a7 7 0 00-9.9 0 1 1 0 01-1.414-1.414 9 9 0 0112.728 0 1 1 0 01-1.414 1.414zM12.12 13.88a3 3 0 00-4.242 0 1 1 0 01-1.415-1.415 5 5 0 017.072 0 1 1 0 01-1.415 1.415zM9 16a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
            ></path>
            <path
              d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7h1a1 1 0 011 1v6.05A2.5 2.5 0 0014 16.5h-1V7z"
            ></path>
          </svg>
        </div>
      </div>
      <!-- App Content -->
      <div class="flex-grow bg-gray-100 pt-6 overflow-y-auto">
        <slot />
      </div>
    </div>
    <!-- Home Indicator -->
    <div
      class="absolute bottom-1 left-1/2 transform -translate-x-1/2 w-32 h-1 bg-black rounded-full"
    ></div>
  </div>
</div>
