<script>
  import { createEventDispatcher } from "svelte";
  import Button from "$components/Button.svelte";
  import Form from "$components/Form.svelte";
  import TipTap from "$components/TipTap.svelte";
  import Ajax from "$utils/ajax";

  const dispatch = createEventDispatcher();

  export let block = null;

  let saving = false;
  let text = block?.content || "";

  const saveText = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    const data = {
      content: text,
      block_type: "text",
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, data)
      : await Ajax.post("/dashboard/blocks", data);

    if (ajaxErrors) {
      console.log(ajaxErrors);
    } else {
      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit" : "Add"} Text
</h2>

<Form>
  <div class="flex flex-col gap-8">
    <TipTap bind:content={text} />
    <Button
      type="submit"
      klass="w-full h-12"
      disabled={saving}
      on:click={saveText}
    >
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update" : "Add"} Text
    </Button>
  </div>
</Form>
