<script>
  import { createEventDispatcher, onMount } from "svelte";
  import Modal from "$components/Modal.svelte";
  import BlockViewer from "$app-components/BlockViewer.svelte";
  import { getThemeFromId } from "$utils/themes";

  export let currentUser;
  export let blocks;
  export let isOpen = false;
  export let updater;

  const dispatch = createEventDispatcher();

  let isMobile = false;

  function closeModal() {
    isOpen = false;
    dispatch("close");
  }

  function checkMobile() {
    isMobile = window.innerWidth < 907;
  }

  function gridDataUpdated(e) {
    if (updater) {
      updater(({ currentUser }) => {
        let gridData = currentUser.grid_data || {};
        gridData["grid"] = e.detail?.grid || [];
        return { currentUser: { ...currentUser, grid_data: gridData } };
      });
    }
  }

  onMount(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  });
</script>

<Modal
  bind:isOpen
  on:close={closeModal}
  size="full"
  closeOnOutsideClick={false}
>
  <div class="h-full flex flex-col">
    <div
      class="py-2 px-4 bg-gradient-to-r from-purple-600 to-indigo-600 border-b border-gray-200 flex items-center"
    >
      <h2 class="text-lg font-semibold text-white">Grid Layout Editor</h2>
      <span class="mx-4 text-white">•</span>
      <p class="text-md text-white font-medium">
        Drag and drop blocks to rearrange
      </p>
    </div>
    <div class="flex-grow border border-slate-200">
      <div class="h-[calc(100vh-200px)] overflow-y-auto">
        {#if isMobile}
          <div
            class="flex items-center justify-center h-full bg-gradient-to-br from-purple-100 to-indigo-100"
          >
            <div
              class="text-center p-8 bg-white rounded-lg shadow-lg mx-4 sm:mx-8 md:mx-16"
            >
              <svg
                class="w-24 h-24 mx-auto text-purple-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
              <h3 class="mt-4 text-2xl font-bold text-gray-800">
                Oops! Screen Too Small
              </h3>
              <p class="mt-2 text-gray-600">
                The Grid Layout Editor works better on a larger screen. Please
                switch to a tablet or desktop for the best experience.
              </p>
            </div>
          </div>
        {:else}
          <BlockViewer
            {blocks}
            gridData={currentUser.grid_data["grid"]}
            font={currentUser.font}
            theme={getThemeFromId(currentUser.theme).theme}
            buttonStyle={currentUser.button_style}
            hoverEffect={currentUser.hover_effect}
            layoutStyle="grid"
            staticGrid={false}
            on:grid-data-updated={gridDataUpdated}
          />
        {/if}
      </div>
    </div>
  </div>
</Modal>
