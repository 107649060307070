<script>
  import Iphone from "$app-components/Iphone.svelte";
  import BlockViewer from "$app-components/BlockViewer.svelte";
  import ProfileHeader from "$app-components/ProfileHeader.svelte";
  import SocialLinks from "$app-components/SocialLinks.svelte";

  export let blocks;
  export let user;
  export let socialLinks;

  export let theme;
  export let buttonStyle;
  export let hoverEffect;
  export let layoutStyle;
  export let font;

  export let width;
  export let height;
</script>

<Iphone {width} {height}>
  <BlockViewer
    {blocks}
    {font}
    {theme}
    {buttonStyle}
    {hoverEffect}
    layoutStyle={"list"}
  >
    <svelte:fragment slot="header">
      <ProfileHeader {user} />
    </svelte:fragment>
    <svelte:fragment slot="footer">
      <SocialLinks links={socialLinks} {theme} />
    </svelte:fragment>
  </BlockViewer>
</Iphone>
