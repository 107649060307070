<script>
  import { createEventDispatcher } from "svelte";
  import Button from "$components/Button.svelte";
  import Input from "$components/Input.svelte";
  import Form from "$components/Form.svelte";
  import Toggle from "$components/Toggle.svelte";
  import Ajax from "$utils/ajax";
  import { notify } from "$utils/notify";

  const dispatch = createEventDispatcher();

  export let block = null;

  let url = block?.url || "";
  let title = block?.title || "";
  let showAutomatically = block?.fields?.show_automatically ?? true;
  let saving = false;
  let errors = {};

  const reset = () => {
    url = "";
    title = "";
    showAutomatically = false;
  };

  const saveYoutubeVideo = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    const payload = {
      url,
      title,
      fields: { show_automatically: showAutomatically },
      block_type: "youtube",
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, payload)
      : await Ajax.post("/dashboard/blocks", payload);

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      if (block) {
        dispatch("edited-block", resp);
      } else {
        reset();
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit" : "Add"} YouTube Video
</h2>

<Form on:submit={saveYoutubeVideo}>
  <div class="flex flex-col gap-6">
    <Input
      bind:value={url}
      type="url"
      name="url"
      placeholder="Enter YouTube URL"
      required
      error={errors.url}
      klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
    />

    <Input
      bind:value={title}
      type="text"
      name="title"
      placeholder="Enter video title (optional)"
      error={errors.title}
      klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
    />

    <div class="flex flex-col mb-6">
      <div class="flex items-center justify-between">
        <label
          for="showAutomatically"
          class="text-sm font-medium text-gray-700"
        >
          Show automatically
        </label>
        <Toggle id="showAutomatically" bind:checked={showAutomatically} />
      </div>
      <p class="text-xs text-gray-500 mt-1">
        If enabled, this YouTube video will appear automatically when the page
        loads.
      </p>
    </div>

    <Button type="submit" klass="w-full h-12" disabled={saving}>
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update" : "Add"} YouTube Video
    </Button>
  </div>
</Form>
