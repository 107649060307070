<script>
  import { getThemeFromId } from "$utils/themes";

  export let user;
  export let klass = "";

  let theme = getThemeFromId(user.theme);
</script>

<div
  class="flex flex-col items-center text-center max-w-md mx-auto py-8 {klass}">
  <img
    src={user.profile_image_url}
    alt="{user.profile_name}'s logo"
    class="w-24 h-24 rounded-full object-cover mb-4 border-1 border-gray-200 shadow-lg" />

  {#if user.profile_name}
    <h1 class="text-2xl mb-2 font-bold" style="color: var(--textColor);">
      {user.profile_name}
    </h1>
  {/if}

  {#if user.bio}
    <p class="text-md leading-snug max-w-xs" style="color: var(--textColor);">
      {user.bio}
    </p>
  {/if}
</div>
