<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { notify } from "$utils/notify";
  import { cropper } from "$utils/cropper";
  import { showUploader } from "$utils/upload";

  import Input from "$components/Input.svelte";
  import Textarea from "$components/Textarea.svelte";
  import Button from "$components/Button.svelte";

  import SocialLinkSettings from "./SocialLinkSettings.svelte";

  export let user;
  export let socialLinks;

  const dispatch = createEventDispatcher();

  let profileImageUrl = user.profile_image_url || "";
  let profileName = user.profile_name || "";
  let bio = user.bio || "";

  $: bioCharCount = 80 - bio.length;

  let isUploading = false;

  onMount(() => {
    // Sortable initialization removed as it's now handled in SocialLinkSettings
  });

  async function handlePickImage() {
    isUploading = true;

    try {
      profileImageUrl = await showUploader({
        crop: cropper.crop,
        fileType: "image",
      });

      dispatchUpdate();
      dispatch("update-avatar", { profileImageUrl: profileImageUrl });
    } catch (error) {
      notify({
        message: "Upload failed. Please try again.",
        type: "error",
      });
    } finally {
      isUploading = false;
    }
  }

  function handleRemoveImage() {
    profileImageUrl = "/images/user.png";
    dispatchUpdate();
  }

  function dispatchUpdate() {
    dispatch("update", {
      profileName: profileName,
      bio: bio,
      profileImageUrl: profileImageUrl,
      socialLinks: socialLinks,
    });
  }

  function handleSocialLinksUpdate(event) {
    socialLinks = event.detail.socialLinks;
    dispatchUpdate();
  }
</script>

<h3 class="text-2xl font-bold text-gray-800 mb-6 mt-8">Profile</h3>

<div class="bg-white shadow-lg rounded-xl p-4 sm:p-8">
  <div class="space-y-5">
    <div class="flex flex-col sm:flex-row items-center">
      <div
        class="flex-shrink-0 relative cursor-pointer mb-4 sm:mb-0"
        on:click={handlePickImage}
      >
        <img
          src={profileImageUrl}
          alt="Avatar"
          class="w-24 h-24 sm:w-32 sm:h-32 rounded-full object-cover border border-gray-300"
        />

        <button
          type="button"
          class="absolute bottom-0 right-0 bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-md transition-colors duration-200 pointer-events-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 sm:h-5 sm:w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="sm:ml-8 text-center sm:text-left">
        <h4 class="text-lg font-semibold text-gray-700 mb-2">
          Profile Picture
        </h4>
        <p class="text-sm text-gray-500 mb-5">
          Click on the image to upload a new profile picture or avatar.
        </p>
        {#if isUploading == false}
          <Button variant="secondary" on:click={handleRemoveImage}>
            Remove Avatar
          </Button>
        {/if}
      </div>
    </div>

    <hr class="border-t border-gray-200 -mx-4 sm:-mx-8 !py-2 !mt-12" />

    <div>
      <Input
        type="text"
        label="Profile Name"
        bind:value={profileName}
        on:input={dispatchUpdate}
        placeholder="Enter your profile name"
        required
      />
    </div>
    <div>
      <Textarea
        name="bio"
        maxLength={80}
        label="Bio"
        rows={2}
        bind:value={bio}
        on:input={dispatchUpdate}
        placeholder="Tell us a bit about yourself..."
      ></Textarea>
      <p
        class="mt-2 text-sm text-gray-500 flex flex-col sm:flex-row sm:justify-between sm:items-center"
      >
        <span class="sm:hidden"
          >Shows up in your profile <span class="float-right"
            >{bioCharCount} characters</span
          ></span
        >
        <span class="hidden sm:inline">Shows up in your profile</span>
        <span class="hidden sm:inline">{bioCharCount} characters remaining</span
        >
      </p>
    </div>

    <hr class="border-t border-gray-200 -mx-4 sm:-mx-8 !py-2 !mt-12" />

    <SocialLinkSettings {socialLinks} on:update={handleSocialLinksUpdate} />
  </div>
</div>
