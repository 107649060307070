<script>
  import { onMount } from "svelte";
  import { fade, fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import { sendEvent } from "$utils/event";
  import { getThemeFromId } from "$utils/themes";
  import { notify } from "$utils/notify";
  import Modal from "$components/Modal.svelte";

  export let profileUser;

  let showModal = false;
  let theme = getThemeFromId(profileUser.theme).theme;
  let userFont = profileUser.font;
  let buttonTop = 0;
  let buttonRight = 0;
  let shareButton;

  const shareOptions = [
    {
      name: "Copy link",
      icon: "fas fa-link",
      color: "#718096",
    },
    {
      name: "X",
      icon: "fab fa-x-twitter",
      color: "#000000",
    },
    {
      name: "Facebook",
      icon: "fab fa-facebook-f",
      color: "#1877F2",
    },
    {
      name: "WhatsApp",
      icon: "fab fa-whatsapp",
      color: "#25D366",
    },
    {
      name: "LinkedIn",
      icon: "fab fa-linkedin-in",
      color: "#0A66C2",
    },
    {
      name: "Messenger",
      icon: "fab fa-facebook-messenger",
      color: "#00B2FF",
    },
    {
      name: "Snapchat",
      icon: "fab fa-snapchat-ghost",
      color: "#FFFC00",
      lineColor: "#000000",
    },
    {
      name: "Pinterest",
      icon: "fab fa-pinterest-p",
      color: "#BD081C",
    },
    {
      name: "Reddit",
      icon: "fab fa-reddit-alien",
      color: "#FF4500",
    },
    {
      name: "Telegram",
      icon: "fab fa-telegram-plane",
      color: "#0088cc",
    },
    {
      name: "Email",
      icon: "fas fa-envelope",
      color: "#D44638",
    },
  ];

  function toggleModal() {
    showModal = !showModal;
    if (showModal) {
      sendEvent("share_button:click", { profile_user_id: profileUser.id });
    }
  }

  function shareViaXUrl() {
    const text = `Check out ${profileUser.profile_name}'s profile!`;
    const url = window.location.href;
    const encodedText = encodeURIComponent(text);
    const encodedUrl = encodeURIComponent(url);

    return `https://x.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`;
  }

  function shareViaFacebookUrl() {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);

    return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  }

  function shareViaWhatsAppUrl() {
    const text = `Check out ${profileUser.profile_name}'s profile!`;
    const url = window.location.href;
    const encodedText = encodeURIComponent(text);
    const encodedUrl = encodeURIComponent(url);

    return `https://wa.me/?text=${encodedText}%20${encodedUrl}`;
  }

  function shareViaLinkedInUrl() {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);
    const title = encodeURIComponent(
      `Check out ${profileUser.profile_name}'s profile!`
    );

    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${title}`;
  }

  function shareViaMessengerUrl() {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);

    return `https://www.facebook.com/dialog/send?app_id=201843466837995&link=${encodedUrl}`;
  }

  function shareViaSnapchatUrl() {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);

    return `https://snapchat.com/add/${encodedUrl}`;
  }

  function shareViaEmailUrl() {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);

    return `mailto:?body=${encodedUrl}`;
  }

  function shareViaPinterestUrl() {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);
    const description = encodeURIComponent(
      `Check out ${profileUser.profile_name}'s profile!`
    );

    return `https://pinterest.com/pin/create/button/?url=${encodedUrl}&description=${description}`;
  }

  function shareViaRedditUrl() {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);
    const title = encodeURIComponent(
      `Check out ${profileUser.profile_name}'s profile!`
    );

    return `https://reddit.com/submit?url=${encodedUrl}&title=${title}`;
  }

  function shareViaTelegramUrl() {
    const text = `Check out ${profileUser.profile_name}'s profile!`;
    const url = window.location.href;
    const encodedText = encodeURIComponent(text);
    const encodedUrl = encodeURIComponent(url);

    return `https://t.me/share/url?url=${encodedUrl}&text=${encodedText}`;
  }

  function handleShare(option) {
    let url;

    switch (option.name) {
      case "X":
        url = shareViaXUrl();
        break;
      case "Facebook":
        url = shareViaFacebookUrl();
        break;
      case "WhatsApp":
        url = shareViaWhatsAppUrl();
        break;
      case "LinkedIn":
        url = shareViaLinkedInUrl();
        break;
      case "Messenger":
        url = shareViaMessengerUrl();
        break;
      case "Snapchat":
        url = shareViaSnapchatUrl();
        break;
      case "Email":
        url = shareViaEmailUrl();
        break;
      case "Pinterest":
        url = shareViaPinterestUrl();
        break;
      case "Reddit":
        url = shareViaRedditUrl();
        break;
      case "Telegram":
        url = shareViaTelegramUrl();
        break;
    }

    if (!url) return;

    const width = 600;
    const height = 450;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(
      url,
      "Share Page",
      `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`
    );

    sendEvent("share_button:share", {
      profile_user_id: profileUser.id,
      share_method: option.name,
    });
  }

  function copyLink() {
    navigator.clipboard.writeText(window.location.href);
    notify({
      message: "Link copied to clipboard",
      type: "success",
    });
  }

  function updateButtonPosition() {
    const firstBlockComponent = document.querySelector(".layout-container");
    if (firstBlockComponent) {
      const rect = firstBlockComponent.getBoundingClientRect();
      buttonTop = 10;
      buttonRight = window.innerWidth - rect.right;
    }
  }

  function handleScroll() {
    if (shareButton) {
      if (window.scrollY > 150) {
        shareButton.classList.add("sb-hidden");
      } else {
        shareButton.classList.remove("sb-hidden");
      }
    }
  }

  onMount(() => {
    updateButtonPosition();
    window.addEventListener("resize", updateButtonPosition);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", updateButtonPosition);
      window.removeEventListener("scroll", handleScroll);
    };
  });
</script>

<style>
  .share-button {
    width: 40px;
    height: 40px;
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    transform: translateY(0);
  }

  :global(.share-button.sb-hidden) {
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    transition:
      opacity 0.1s ease-out,
      transform 0.1s ease-out;
  }

  .share-button:hover {
    opacity: 1;
  }
</style>

<button
  bind:this={shareButton}
  on:click={toggleModal}
  class="fixed top-4 z-50 text-white -mr-2 p-2 rounded-full transition-all duration-300 ease-in-out focus:outline-none flex items-center justify-center overflow-hidden share-button opacity-80"
  style="right: {buttonRight}px; color: {theme.textColor}; font-family: {userFont};">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5">
    <path
      fill="currentColor"
      d="M5 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
  </svg>
</button>

<Modal bind:isOpen={showModal} size="lg">
  <svelte:fragment slot="header">
    <h2 class="text-lg sm:text-xl font-bold text-center text-gray-800">
      Share Page
    </h2>
  </svelte:fragment>

  <div class="mt-6 mb-8 sm:mb-8 relative">
    <div
      class="w-full h-36 sm:h-48 rounded-lg overflow-hidden flex flex-col items-center justify-center"
      style="background-image: {theme.backgroundImage}; background-size: cover; background-position: center;">
      <img
        src={profileUser.profile_image_url}
        alt={profileUser.profile_name}
        class="w-20 h-20 sm:w-28 sm:h-28 rounded-full border-4 border-white object-cover shadow-lg mb-2" />
      <p
        class="text-lg sm:text-xl font-semibold text-center"
        style="color: {theme.textColor};">
        {profileUser.profile_name}
      </p>
    </div>
  </div>

  <div class="grid grid-cols-3 sm:grid-cols-4 gap-2">
    {#each shareOptions as option}
      <div class="border border-gray-200 rounded-lg p-0">
        <button
          on:click={() =>
            option.name === "Copy link" ? copyLink() : handleShare(option)}
          class="flex flex-col items-center justify-center p-2 sm:p-3 bg-white rounded-lg hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full h-full">
          <div
            class="w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center rounded-full mb-1 sm:mb-2"
            style="background-color: {option.color};">
            <i
              class="{option.icon} text-lg sm:text-xl"
              style="color: {option.lineColor || 'white'};"></i>
          </div>
          <span
            class="text-[10px] sm:text-[11px] text-center text-gray-600 font-semibold tracking-wide uppercase mt-1"
            >{option.name}</span>
        </button>
      </div>
    {/each}
  </div>
</Modal>
