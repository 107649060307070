<script>
  import Sidebar from "$layouts/shared/Sidebar.svelte";
  import Logo from "$components/Logo.svelte";

  let toggleSidebar;
</script>

<div class="flex overflow-hidden bg-slate-50">
  <Sidebar bind:toggleSidebar />

  <!-- Main content -->
  <div class="flex-1 overflow-hidden lg:pl-72">
    <div class="lg:hidden">
      <div
        class="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5"
      >
        <div>
          <Logo klass="h-8 w-auto" size="text-2xl" />
        </div>
        <div>
          <button
            on:click={toggleSidebar}
            type="button"
            class="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
          >
            <span class="sr-only">Open sidebar</span>
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <main class="flex-1 relative z-50 overflow-y-auto focus:outline-none">
      <div class="py-8 relative">
        <div class="px-4 sm:px-6 lg:px-8">
          <slot />
        </div>
      </div>
    </main>
  </div>
</div>
