<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";
  import { fade, fly } from "svelte/transition";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;

  const dispatch = createEventDispatcher();

  let videoFrame;
  let transitionDuration = "200ms";
  let showModal = false;

  function getYoutubeId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }

  function toggleModal() {
    if (!block.fields?.show_automatically) {
      showModal = !showModal;
      dispatch("block-expand", { blockId: block.id, expanded: showModal });
    }
  }

  $: youtubeId = getYoutubeId(block.url);
  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, transitionDuration);
  $: blockRadius = getBorderRadius(buttonStyle);
  $: showAutomatically = block.fields?.show_automatically;

  onMount(() => {
    dispatch("mounted");
  });
</script>

<div
  class="overflow-hidden transition-all ease-in-out w-full relative"
  style={currentButtonStyle}
  use:useHoverEffect={{
    theme,
    buttonStyle,
    hoverEffect,
    transitionDuration,
    getBlockStyle,
  }}>
  <div
    class="flex flex-col transition-colors ease-in-out cursor-pointer"
    style="
      color: {buttonStyle.startsWith('fill')
      ? 'var(--buttonTextColor)'
      : 'var(--linkColor)'};
      transition-duration: {transitionDuration};
    "
    on:click={toggleModal}>
    <div class="flex items-center p-2 {showAutomatically ? 'py-1' : 'p-4'}">
      {#if block.image_url}
        <img
          src={block.image_url}
          alt={block.title}
          class="{showAutomatically
            ? 'w-8 h-8'
            : 'w-12 h-12'} object-cover mr-4 flex-shrink-0"
          style="border-radius: {blockRadius}" />
      {:else}
        <div
          class="{showAutomatically
            ? 'w-6 h-6'
            : 'w-8 h-8'} mr-2 flex items-center justify-center flex-shrink-0"
          style="
            background-color: var(--imagePlaceholderColor);
            border-radius: {blockRadius};
          ">
          <span
            class="text-xs font-bold"
            style="color: var(--imagePlaceholderTextColor);"
            >{block?.title?.[0]}</span>
        </div>
      {/if}
      <div class="flex-grow">
        <h2
          class="{showAutomatically
            ? 'line-clamp-1 text-sm'
            : 'line-clamp-2'} cursor-pointer"
          style="
          font-weight: var(--titleFontWeight);
          font-size: {showAutomatically
            ? 'calc(var(--titleFontSize) * 0.7)'
            : 'calc(var(--titleFontSize) * 0.8)'};
        "
          on:click|stopPropagation={() => {
            if (youtubeId && showAutomatically) {
              window.open(
                `https://www.youtube.com/watch?v=${youtubeId}`,
                "_blank"
              );
            } else {
              toggleModal();
            }
          }}>
          {block.title}
        </h2>
      </div>
    </div>
    {#if showAutomatically && youtubeId}
      <div class="relative pt-[56.25%]">
        <iframe
          src="https://www.youtube.com/embed/{youtubeId}"
          title={block.title}
          class="absolute top-0 left-0 w-full h-full"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    {/if}
  </div>
</div>

{#if showModal && youtubeId && !showAutomatically}
  <div
    class="fixed inset-0 bg-black bg-opacity-75 flex flex-col justify-center items-center z-50"
    on:click={toggleModal}
    transition:fade={{ duration: 100 }}>
    <div
      class="bg-white rounded-lg w-[95%] max-w-5xl relative"
      on:click|stopPropagation
      transition:fly={{ y: 200, duration: 500 }}>
      <div class="relative pt-[56.25%]">
        <iframe
          bind:this={videoFrame}
          src="https://www.youtube.com/embed/{youtubeId}"
          title={block.title}
          class="absolute top-0 left-0 w-full h-full"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
    <div
      class="flex items-center justify-center mt-4"
      transition:fly={{ y: 50, duration: 500, delay: 200 }}>
      <button
        class="bg-white text-gray-800 rounded-full px-4 py-2 flex items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 shadow-lg"
        on:click|stopPropagation|preventDefault={toggleModal}>
        <i class="fas fa-times mr-2"></i>
        <span>Close</span>
      </button>
    </div>
  </div>
{/if}
