const standard = [
  {
    id: "classic-blue",
    name: "Classic Blue",
    theme: {
      backgroundColor: "#f0f8ff",
      textColor: "#333333",
      linkColor: "#0066cc",
      linkHoverColor: "#004080",
      buttonColor: "#0066cc",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#cbd5e0",
      imagePlaceholderTextColor: "#718096",
    },
  },
  {
    id: "mint-fresh",
    name: "Mint Fresh",
    theme: {
      backgroundColor: "#e0f5e9",
      textColor: "#2c3e50",
      linkColor: "#16a085",
      linkHoverColor: "#1abc9c",
      buttonColor: "#2ecc71",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#a3e4d7",
      imagePlaceholderTextColor: "#16a085",
    },
  },
  {
    id: "sunset-vibes",
    name: "Sunset Vibes",
    theme: {
      backgroundColor: "#ffeaa7",
      textColor: "#6d214f",
      linkColor: "#e17055",
      linkHoverColor: "#d63031",
      buttonColor: "#e17055",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(109, 33, 79, 0.1)",
      cardHoverShadowColor: "rgba(109, 33, 79, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#ffeaa7",
      imagePlaceholderTextColor: "#e17055",
    },
  },
  {
    id: "lavender-dreams",
    name: "Lavender Dreams",
    theme: {
      backgroundColor: "#e6e6fa",
      textColor: "#4a4a4a",
      linkColor: "#9b59b6",
      linkHoverColor: "#8e44ad",
      buttonColor: "#9b59b6",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(155, 89, 182, 0.1)",
      cardHoverShadowColor: "rgba(155, 89, 182, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e8daef",
      imagePlaceholderTextColor: "#9b59b6",
    },
  },
  {
    id: "ocean-breeze",
    name: "Ocean Breeze",
    theme: {
      backgroundColor: "#e0f7fa",
      textColor: "#01579b",
      linkColor: "#0288d1",
      linkHoverColor: "#03a9f4",
      buttonColor: "#00bcd4",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(2, 136, 209, 0.1)",
      cardHoverShadowColor: "rgba(2, 136, 209, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0f7fa",
      imagePlaceholderTextColor: "#0288d1",
    },
  },
  {
    id: "forest-retreat",
    name: "Forest Retreat",
    theme: {
      backgroundColor: "#e8f5e9",
      textColor: "#1b5e20",
      linkColor: "#388e3c",
      linkHoverColor: "#4caf50",
      buttonColor: "#66bb6a",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(56, 142, 60, 0.1)",
      cardHoverShadowColor: "rgba(56, 142, 60, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e8f5e9",
      imagePlaceholderTextColor: "#388e3c",
    },
  },
  {
    id: "cherry-blossom",
    name: "Cherry Blossom",
    theme: {
      backgroundColor: "#fff0f5",
      textColor: "#5d001e",
      linkColor: "#e91e63",
      linkHoverColor: "#c2185b",
      buttonColor: "#f06292",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(233, 30, 99, 0.1)",
      cardHoverShadowColor: "rgba(233, 30, 99, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#fce4ec",
      imagePlaceholderTextColor: "#e91e63",
    },
  },
  {
    id: "golden-hour",
    name: "Golden Hour",
    theme: {
      backgroundColor: "#fff8e1",
      textColor: "#5d4037",
      linkColor: "#ff9800",
      linkHoverColor: "#f57c00",
      buttonColor: "#ffa726",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(255, 152, 0, 0.1)",
      cardHoverShadowColor: "rgba(255, 152, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#fff8e1",
      imagePlaceholderTextColor: "#ff9800",
    },
  },
  {
    id: "monochrome",
    name: "Monochrome",
    theme: {
      backgroundColor: "#f5f5f5",
      textColor: "#212121",
      linkColor: "#616161",
      linkHoverColor: "#424242",
      buttonColor: "#9e9e9e",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(33, 33, 33, 0.1)",
      cardHoverShadowColor: "rgba(33, 33, 33, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#eeeeee",
      imagePlaceholderTextColor: "#616161",
    },
  },
  {
    id: "neon-nights",
    name: "Neon Nights",
    theme: {
      backgroundColor: "#212121",
      textColor: "#ffffff",
      linkColor: "#00ffff",
      linkHoverColor: "#00cccc",
      buttonColor: "#ff00ff",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#333333",
      cardShadowColor: "rgba(0, 255, 255, 0.1)",
      cardHoverShadowColor: "rgba(0, 255, 255, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#333333",
      imagePlaceholderTextColor: "#00ffff",
    },
  },
];

const minimalist = [
  {
    id: "marble-elegance",
    name: "Marble Elegance",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/marble-elegance.jpg')",
      textColor: "#333333",
      linkColor: "#0077b6",
      linkHoverColor: "#005f8a",
      buttonColor: "#0077b6",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0e0e0",
      imagePlaceholderTextColor: "#757575",
    },
  },
  {
    id: "minimalist-white",
    name: "Minimalist White",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/minimalist-white.jpg')",
      textColor: "#333333",
      linkColor: "#007acc",
      linkHoverColor: "#005f99",
      buttonColor: "#007acc",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.05)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.1)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e2e8f0",
      imagePlaceholderTextColor: "#a0aec0",
    },
  },
  {
    id: "serene-sands",
    name: "Serene Sands",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-sands.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#d68c45",
      linkHoverColor: "#b3743a",
      buttonColor: "#d68c45",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0d5c4",
      imagePlaceholderTextColor: "#a89b8a",
    },
  },
  {
    "id": "modern-gray",
    "name": "Modern Gray",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/modern-gray.jpg')",
      "textColor": "#333333",
      "linkColor": "#555555",
      "linkHoverColor": "#777777",
      "buttonColor": "#555555",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#ffffff",
      "cardShadowColor": "rgba(0, 0, 0, 0.1)",
      "cardHoverShadowColor": "rgba(0, 0, 0, 0.2)",
      "cardBorderRadius": "0.5rem",
      "imagePlaceholderColor": "#e2e8f0",
      "imagePlaceholderTextColor": "#a0aec0"
    }
  },
  {
    id: "serene-nature",
    name: "Serene Nature",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-nature.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#6b8e23",
      linkHoverColor: "#556b2f",
      buttonColor: "#8fbc8f",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#f5f5f5",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#dcdcdc",
      imagePlaceholderTextColor: "#a9a9a9",
    },
  },
  {
    id: "minimal-blush",
    name: "Minimal Blush",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/minimal-blush.jpg')",
      textColor: "#5a3e36",
      linkColor: "#b5838d",
      linkHoverColor: "#8d5a5a",
      buttonColor: "#b5838d",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#f0e1dd",
      imagePlaceholderTextColor: "#a68a7c",
    },
  },
  {
    id: "pastel-harmony",
    name: "Pastel Harmony",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/pastel-harmony.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#ff6f61",
      linkHoverColor: "#d45d50",
      buttonColor: "#ff6f61",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0e0e0",
      imagePlaceholderTextColor: "#9e9e9e",
    },
  },
  {
    id: "serene-mountains",
    name: "Serene Mountains",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-mountains.jpg')",
      textColor: "#fff",
      linkColor: "#1a73e8",
      linkHoverColor: "#0c47a1",
      buttonColor: "#1a73e8",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#eeeeee",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0e7ff",
      imagePlaceholderTextColor: "#4a5568",
    },
  },
  {
    id: "minimal-elegance",
    name: "Minimal Elegance",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/minimal-elegance.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#8b5e3c",
      linkHoverColor: "#6b4226",
      buttonColor: "#8b5e3c",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e2e8f0",
      imagePlaceholderTextColor: "#a0aec0",
    },
  },
  {
    id: "pastel-colors",
    name: "Pastel Colors",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/pastel-colors.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#b5838d",
      linkHoverColor: "#6d6875",
      buttonColor: "#b5838d",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e8e8e8",
      imagePlaceholderTextColor: "#9e9e9e",
    },
  },
  {
    id: "serene-white",
    name: "Serene White",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-white.jpg')",
      textColor: "#333333",
      linkColor: "#666666",
      linkHoverColor: "#444444",
      buttonColor: "#f0f0f0",
      buttonTextColor: "#333333",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0e0e0",
      imagePlaceholderTextColor: "#999999",
    },
  },
  {
    id: "minimal-arch",
    name: "Minimal Arch",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/minimal-arch.jpg')",
      textColor: "#333333",
      linkColor: "#8c8c8c",
      linkHoverColor: "#666666",
      buttonColor: "#cccccc",
      buttonTextColor: "#333333",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0e0e0",
      imagePlaceholderTextColor: "#999999",
    },
  },
  {
    id: "soft-sand",
    name: "Soft Sand",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/soft-sand.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#b8860b",
      linkHoverColor: "#8b6508",
      buttonColor: "#b8860b",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0d7c6",
      imagePlaceholderTextColor: "#7a7a7a",
    },
  },
  {
    id: "serene-sky",
    name: "Serene Sky",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/serene-sky.jpg')",
      textColor: "#333333",
      linkColor: "#005f99",
      linkHoverColor: "#003d66",
      buttonColor: "#005f99",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0f7ff",
      imagePlaceholderTextColor: "#5a5a5a",
    },
  },
  {
    id: "minimalist-serenity",
    name: "Minimalist Serenity",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/minimalist-serenity.jpg')",
      textColor: "#333333",
      linkColor: "#007acc",
      linkHoverColor: "#005f99",
      buttonColor: "#007acc",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e2e8f0",
      imagePlaceholderTextColor: "#4a5568",
    },
  },
  {
    id: "soft-blush",
    name: "Soft Blush",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/soft-blush.jpg')",
      textColor: "#4a4a4a",
      linkColor: "#d17b88",
      linkHoverColor: "#b15c6a",
      buttonColor: "#d17b88",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#f0e6e9",
      imagePlaceholderTextColor: "#a8a8a8",
    },
  },
  {
    "id": "serene-green",
    "name": "Serene Green",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/serene-green.jpg')",
      "textColor": "#2f4f4f",
      "linkColor": "#006400",
      "linkHoverColor": "#228b22",
      "buttonColor": "#32cd32",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#f0fff0",
      "cardShadowColor": "rgba(0, 100, 0, 0.1)",
      "cardHoverShadowColor": "rgba(0, 100, 0, 0.2)",
      "cardBorderRadius": "0.5rem",
      "imagePlaceholderColor": "#e0f2f1",
      "imagePlaceholderTextColor": "#004d40"
    }
  }
];

const fun = [
  {
    id: "pastel-bokeh",
    name: "Pastel Bokeh",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/pastel-bokeh.jpg')",
      textColor: "#333333",
      linkColor: "#ff6f61",
      linkHoverColor: "#ff4b3e",
      buttonColor: "#ff6f61",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0f7fa",
      imagePlaceholderTextColor: "#00796b",
    },
  },
  {
    id: "mystic-shadow",
    name: "Mystic Shadow",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/mystic-shadow.jpg')",
      textColor: "#e0e0e0",
      linkColor: "#ffcc00",
      linkHoverColor: "#ff9900",
      buttonColor: "#333333",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#444444",
      cardShadowColor: "rgba(0, 0, 0, 0.5)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.7)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#666666",
      imagePlaceholderTextColor: "#cccccc",
    },
  },
  {
    id: "vintage-brown",
    name: "Vintage Brown",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/vintage-brown.jpg')",
      textColor: "#fff",
      linkColor: "#8b4513",
      linkHoverColor: "#5f3310",
      buttonColor: "#8b4513",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#f5f5dc",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#d3c4a4",
      imagePlaceholderTextColor: "#6b4f3f",
    },
  },
  {
    id: "mint-breeze",
    name: "Mint Breeze",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/mint-breeze.jpg')",
      textColor: "#2c3e50",
      linkColor: "#16a085",
      linkHoverColor: "#1abc9c",
      buttonColor: "#16a085",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(44, 62, 80, 0.1)",
      cardHoverShadowColor: "rgba(44, 62, 80, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#ecf0f1",
      imagePlaceholderTextColor: "#95a5a6",
    },
  },
  {
    id: "sunset-bliss",
    name: "Sunset Bliss",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/sunset-bliss.jpg')",
      textColor: "#333333",
      linkColor: "#ff6f61",
      linkHoverColor: "#ff4b3e",
      buttonColor: "#ff6f61",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e2e8f0",
      imagePlaceholderTextColor: "#4a5568",
    },
  },
  {
    id: "vintage-parchment",
    name: "Vintage Parchment",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/vintage-parchment.jpeg')",
      textColor: "#fff",
      linkColor: "#8b5e3c",
      linkHoverColor: "#5e3c1b",
      buttonColor: "#8b5e3c",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#f5f0e6",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#d3c4a8",
      imagePlaceholderTextColor: "#7a6a52",
    },
  },
  {
    id: "night-bokeh",
    name: "Night Bokeh",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/night-bokeh.jpeg')",
      textColor: "#ffffff",
      linkColor: "#ff6347",
      linkHoverColor: "#ff4500",
      buttonColor: "#ff6347",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#1a1a1a",
      cardShadowColor: "rgba(0, 0, 0, 0.5)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.7)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#333333",
      imagePlaceholderTextColor: "#aaaaaa",
    },
  },
  {
    id: "dreamy-swirl",
    name: "Dreamy Swirl",
    theme: {
      backgroundImage:
        "url('/images/theme-backgrounds/dreamy-swirl.jpg')",
      textColor: "#333333",
      linkColor: "#ff4500",
      linkHoverColor: "#e63900",
      buttonColor: "#00bfff",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#ffffff",
      cardShadowColor: "rgba(0, 0, 0, 0.1)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.2)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#e0f7fa",
      imagePlaceholderTextColor: "#006064",
    },
  },
  {
    id: "cosmic-dusk",
    name: "Cosmic Dusk",
    theme: {
      backgroundImage: "url('/images/theme-backgrounds/cosmic-dusk.jpeg')",
      textColor: "#ffffff",
      linkColor: "#ffd700",
      linkHoverColor: "#ff4500",
      buttonColor: "#4b0082",
      buttonTextColor: "#ffffff",
      cardBackgroundColor: "#2f4f4f",
      cardShadowColor: "rgba(0, 0, 0, 0.3)",
      cardHoverShadowColor: "rgba(0, 0, 0, 0.5)",
      cardBorderRadius: "0.5rem",
      imagePlaceholderColor: "#333333",
      imagePlaceholderTextColor: "#cccccc",
    },
  },
  {
    "id": "neon-brick",
    "name": "Neon Brick",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/neon-brick.jpg')",
      "textColor": "#ffffff",
      "linkColor": "#ff66cc",
      "linkHoverColor": "#ff33aa",
      "buttonColor": "#6600cc",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#330066",
      "cardShadowColor": "rgba(0, 0, 0, 0.3)",
      "cardHoverShadowColor": "rgba(0, 0, 0, 0.5)",
      "cardBorderRadius": "0.5rem",
      "imagePlaceholderColor": "#4c0073",
      "imagePlaceholderTextColor": "#b266ff"
    }
  },
  {
    "id": "pastel-dream",
    "name": "Pastel Dream",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/pastel-dream.jpg')",
      "textColor": "#4a4a4a",
      "linkColor": "#ff6f91",
      "linkHoverColor": "#ff3d68",
      "buttonColor": "#ff6f91",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "#ffffff",
      "cardShadowColor": "rgba(0, 0, 0, 0.1)",
      "cardHoverShadowColor": "rgba(0, 0, 0, 0.2)",
      "cardBorderRadius": "0.5rem",
      "imagePlaceholderColor": "#e0e7ff",
      "imagePlaceholderTextColor": "#6b7280"
    }
  },
  {
    "id": "neon-mist",
    "name": "Neon Mist",
    "theme": {
      "backgroundImage": "url('/images/theme-backgrounds/neon-mist.jpg')",
      "textColor": "#ffffff",
      "linkColor": "#8b0046",
      "linkHoverColor": "#6d0037",
      "buttonColor": "#33ccff",
      "buttonTextColor": "#ffffff",
      "cardBackgroundColor": "rgba(255, 255, 255, 0.8)",
      "cardShadowColor": "rgba(0, 0, 0, 0.2)",
      "cardHoverShadowColor": "rgba(0, 0, 0, 0.3)",
      "cardBorderRadius": "0.5rem",
      "imagePlaceholderColor": "#e0e0e0",
      "imagePlaceholderTextColor": "#757575"
    }
  }
];

const all = [...standard, ...minimalist, ...fun];

const getThemeFromId = (id) => {
  return all.find((t) => t.id === id) || all[0];
};

export { standard, minimalist, fun, all, getThemeFromId };
