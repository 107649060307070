<script>
  import { onMount, onDestroy } from "svelte";
  import { Editor } from "@tiptap/core";
  import StarterKit from "@tiptap/starter-kit";
  import Underline from "@tiptap/extension-underline";
  import BulletList from "@tiptap/extension-bullet-list";
  import ListItem from "@tiptap/extension-list-item";
  import Heading from "@tiptap/extension-heading";
  import TextStyle from "@tiptap/extension-text-style";
  import FontFamily from "@tiptap/extension-font-family";
  import Color from "@tiptap/extension-color";

  let element;
  let editor;
  let showFontDropdown = false;
  let selectedFont = "Default";
  let selectedColor = "#000000";

  // New variables for active states
  let isBold = false;
  let isItalic = false;
  let isUnderline = false;
  let isStrikethrough = false;
  let isH1 = false;
  let isH2 = false;
  let isBulletList = false;

  export let content = "";

  const fontStyles = [
    "Default",
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Comic Sans MS",
    "Trebuchet MS",
    "Arial Black",
    "Impact",
    "Tahoma",
    "Lucida Sans",
    "Monaco",
  ];

  onMount(() => {
    editor = new Editor({
      element: element,
      extensions: [
        StarterKit,
        Underline,
        BulletList,
        ListItem,
        Heading.configure({
          levels: [1, 2],
        }),
        TextStyle,
        FontFamily,
        Color,
      ],
      content: content,
      placeholder: "Start typing here...",
      onUpdate: ({ editor }) => {
        content = editor.getHTML();
        updateSelectedFont();
        updateSelectedColor();
        updateActiveStates();
      },
      onSelectionUpdate: ({ editor }) => {
        updateSelectedFont();
        updateSelectedColor();
        updateActiveStates();
      },
    });
  });

  onDestroy(() => {
    if (editor) {
      editor.destroy();
    }
  });

  onMount(() => {
    if (content) {
      editor.commands.setContent(content);
    }
  });

  function setFontFamily(font) {
    if (font === "Default") {
      editor.chain().focus().unsetFontFamily().run();
    } else {
      editor.chain().focus().setFontFamily(font).run();
    }
    selectedFont = font;
    showFontDropdown = false;
  }

  function setColor(color) {
    editor.chain().focus().setColor(color).run();
    selectedColor = color;
  }

  function rgbToHex(rgb) {
    // Check if the color is already in hex format
    if (rgb.startsWith("#")) {
      return rgb;
    }

    // Extract RGB values
    const rgbValues = rgb.match(/\d+/g);
    if (!rgbValues || rgbValues.length !== 3) {
      return rgb; // Return original value if not a valid RGB
    }

    // Convert RGB to hex
    return (
      "#" +
      rgbValues
        .map((x) => {
          const hex = parseInt(x).toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        })
        .join("")
    );
  }

  function toggleFontDropdown() {
    showFontDropdown = !showFontDropdown;
  }

  function updateSelectedFont() {
    const currentFont = editor.getAttributes("textStyle").fontFamily;
    selectedFont = currentFont || "Default";
  }

  function updateSelectedColor() {
    const currentColor = editor.getAttributes("textStyle").color;
    selectedColor = currentColor || "#000000";
  }

  function updateActiveStates() {
    isBold = editor.isActive("bold");
    isItalic = editor.isActive("italic");
    isUnderline = editor.isActive("underline");
    isStrikethrough = editor.isActive("strike");
    isH1 = editor.isActive("heading", { level: 1 });
    isH2 = editor.isActive("heading", { level: 2 });
    isBulletList = editor.isActive("bulletList");
  }
</script>

<div class="editor-container">
  {#if editor}
    <div class="editor-toolbar">
      <button
        class="editor-button"
        class:active={isBold}
        on:click={() => {
          editor.chain().focus().toggleBold().run();
          isBold = !isBold;
        }}
        aria-label="Bold"
      >
        <svg
          class="icon"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 4h8a4 4 0 014 4 4 4 0 01-4 4H6v-8zm0 8h9a4 4 0 014 4 4 4 0 01-4 4H6v-8z"
          />
        </svg>
      </button>
      <button
        class="editor-button"
        class:active={isItalic}
        on:click={() => {
          editor.chain().focus().toggleItalic().run();
          isItalic = !isItalic;
        }}
        aria-label="Italic"
      >
        <svg
          class="icon"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z" />
        </svg>
      </button>
      <button
        class="editor-button"
        class:active={isUnderline}
        on:click={() => {
          editor.chain().focus().toggleUnderline().run();
          isUnderline = !isUnderline;
        }}
        aria-label="Underline"
      >
        <svg
          class="icon"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3h-2.5v7a3.5 3.5 0 0 1-3.5 3.5 3.5 3.5 0 0 1-3.5-3.5V3H6zm-2 18h16v-2H4v2z"
          />
        </svg>
      </button>
      <button
        class="editor-button"
        class:active={isStrikethrough}
        on:click={() => {
          editor.chain().focus().toggleStrike().run();
          isStrikethrough = !isStrikethrough;
        }}
        aria-label="Strikethrough"
      >
        <svg
          class="icon"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"
          />
        </svg>
      </button>
      <button
        class="editor-button"
        class:active={isH1}
        on:click={() => {
          editor.chain().focus().toggleHeading({ level: 1 }).run();
          isH1 = !isH1;
        }}
        aria-label="Heading 1"
      >
        H1
      </button>
      <button
        class="editor-button"
        class:active={isH2}
        on:click={() => {
          editor.chain().focus().toggleHeading({ level: 2 }).run();
          isH2 = !isH2;
        }}
        aria-label="Heading 2"
      >
        H2
      </button>
      <button
        class="editor-button"
        class:active={isBulletList}
        on:click={() => {
          editor.chain().focus().toggleBulletList().run();
          isBulletList = !isBulletList;
        }}
        aria-label="Bullet List"
      >
        <svg
          class="icon"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 4h2v2H3V4zm0 7h2v2H3v-2zm0 7h2v2H3v-2zm4-14h14v2H7V4zm0 7h14v2H7v-2zm0 7h14v2H7v-2z"
          />
        </svg>
      </button>
      <div class="font-dropdown">
        <button
          class="editor-button"
          on:click={toggleFontDropdown}
          aria-label="Font Family"
        >
          {selectedFont}
          <svg
            class="dropdown-arrow"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d={showFontDropdown ? "M2 8L6 4L10 8" : "M2 4L6 8L10 4"}
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        {#if showFontDropdown}
          <div class="font-dropdown-content">
            {#each fontStyles as style}
              <button
                class="font-option"
                on:click={() => setFontFamily(style)}
                style="font-family: {style === 'Default' ? 'inherit' : style};"
              >
                {style}
              </button>
            {/each}
          </div>
        {/if}
      </div>
      <div class="color-picker">
        <input
          class="color-picker-input"
          type="color"
          value={rgbToHex(selectedColor)}
          on:input={(e) => setColor(e.target.value)}
          aria-label="Text Color"
        />
      </div>
    </div>
  {/if}
  <div class="editor-content" bind:this={element} />
</div>

<style lang="scss">
  .editor-container {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .editor-toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    padding: 0.5rem;
    background-color: #f9fafb;
    border-bottom: 1px solid #e5e7eb;
  }

  .editor-button {
    padding: 0.25rem 0.5rem;
    border: none;
    background-color: transparent;
    color: #374151;
    border-radius: 0.25rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .editor-button:hover {
    background-color: #e5e7eb;
  }

  .editor-button.active {
    background-color: #3b82f6;
    color: white;
  }

  .editor-content {
    padding: 1rem;
    min-height: 100px;
  }

  .icon {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }

  .font-dropdown {
    position: relative;
    width: 150px;
  }

  .font-dropdown .editor-button {
    width: 100%;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid #d1d5db;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #1f2937;
    transition: all 0.2s ease-in-out;

    &:hover {
      border-color: #9ca3af;
    }

    &:focus {
      outline: none;
    }
  }

  .font-dropdown-content {
    position: fixed;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #9ca3af #f3f4f6;
    min-width: 150px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f3f4f6;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9ca3af;
      border-radius: 20px;
      border: 2px solid #f3f4f6;
    }
  }

  .font-option {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #1f2937;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #f3f4f6;
    }

    &:focus {
      outline: none;
      background-color: #e5e7eb;
    }
  }

  .color-picker input[type="color"] {
    width: 30px;
    height: 30px;
    padding: 0;
    margin-top: 5px;
    margin-left: 5px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .color-picker input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  .color-picker input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }

  /* Remove focus outline */
  * :global(.tiptap:focus) {
    outline: none;
  }
</style>
