<script>
  import Blocks from "./dashboard/Blocks.svelte";

  export let count;
  export let name;

  let updater = function (data) {
    if ("count" in data) {
      count = data.count;
    }

    if ("name" in data) {
      name = data.name;
    }
  };
</script>

<Blocks {count} {name} {updater} />
