export function getBorderRadius(roundness) {
  switch (roundness) {
    case "fill-square":
    case "outline-square":
    case "softshadow-square":
    case "hardshadow-square":
      return "0";
    case "fill-pill":
    case "outline-pill":
    case "softshadow-pill":
    case "hardshadow-pill":
      return "9999px";
    case "fill-rounded":
    case "outline-rounded":
    case "softshadow-rounded":
    case "hardshadow-rounded":
    default:
      return "0.5rem";
  }
}

export function getBlockStyle(theme, roundness, transitionDuration) {
  let style = `
    background-color: var(--cardBackgroundColor);
    transition-duration: ${transitionDuration};
    border-radius: ${getBorderRadius(roundness)};
  `;

  switch (roundness) {
    case "fill-square":
    case "fill-rounded":
    case "fill-pill":
      style += `
        background-color: var(--buttonColor);
        color: var(--buttonTextColor);
      `;
      break;
    case "outline-square":
    case "outline-rounded":
    case "outline-pill":
      style += `
        border-style: solid;
        border-color: var(--buttonColor);
        border-width: ${theme.cardBorderWidth || "1px"};
        color: var(--buttonColor);
      `;
      break;
    case "softshadow-square":
    case "softshadow-rounded":
    case "softshadow-pill":
      style += `
        box-shadow: 0 4px 6px var(--cardShadowColor);
      `;
      break;
    case "hardshadow-square":
    case "hardshadow-rounded":
    case "hardshadow-pill":
      style += `
        box-shadow: 6px 6px 0 0 var(--cardShadowColor);
        border-style: solid;
        border-color: var(--buttonColor);
        border-width: ${theme.cardBorderWidth || "1px"};
      `;
      break;
    default:
      style += `
        box-shadow: 0 4px 6px var(--cardShadowColor);
      `;
  }

  return style;
}
