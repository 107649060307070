<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { getBlockStyle } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";
  import { formatCurrency } from "$utils/currency";
  import Carousel from "$components/Carousel.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;

  let transitionDuration = "200ms";
  let images = block.fields?.images || [];
  let isHovered = false;

  let url = block.fields?.buy_link;

  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, transitionDuration);
  $: formattedPrice =
    block.fields?.price && parseFloat(block.fields?.price) !== 0
      ? formatCurrency(block.fields?.price, block.fields?.currency)
      : null;

  function handleMouseEnter() {
    isHovered = true;
  }

  function handleMouseLeave() {
    isHovered = false;
  }

  function handleBlockClick() {
    dispatch("block-click", { block_id: block.id });
    setTimeout(() => (location.href = url), 50);
  }

  onMount(() => {
    dispatch("mounted");
  });
</script>

<div
  class="overflow-hidden transition-all ease-in-out w-full relative"
  style={currentButtonStyle}
  use:useHoverEffect={{
    theme,
    buttonStyle,
    hoverEffect,
    transitionDuration,
    getBlockStyle,
  }}
  on:mouseenter={handleMouseEnter}
  on:mouseleave={handleMouseLeave}
>
  <a
    href={url}
    on:click|preventDefault={handleBlockClick}
    target="_blank"
    rel="noopener noreferrer"
    class="block transition-colors ease-in-out"
    style="
      color: {buttonStyle && buttonStyle.startsWith('fill')
      ? 'var(--buttonTextColor)'
      : 'var(--linkColor)'};
      transition-duration: {transitionDuration};
    "
  >
    <div class="flex flex-col">
      <div class="w-full aspect-square overflow-hidden relative">
        {#if images.length > 0}
          <Carousel
            items={images}
            showArrows={images.length > 1}
            showDots={images.length > 1}
            autoplay={false}
          >
            <div slot="item" let:index let:item={image} class="w-full h-full">
              <img
                src={image}
                alt={block.title}
                class="w-full h-full object-cover"
              />

              {#if formattedPrice && isHovered && index === 0}
                <div
                  in:fade={{ duration: 100 }}
                  out:fade={{ duration: 100 }}
                  class="absolute top-3 right-3 bg-emerald-700 text-white px-3 py-1.5 rounded-full text-sm font-bold transition-opacity duration-200 ease-in-out"
                  style="opacity: {isHovered ? 1 : 0};"
                >
                  {formattedPrice}
                </div>
              {/if}
            </div>
          </Carousel>
        {:else}
          <div
            class="w-full h-full flex items-center justify-center"
            style="background-color: var(--imagePlaceholderColor);"
          >
            <span
              style="
              color: var(--imagePlaceholderTextColor);
              font-weight: var(--titleFontWeight);
              font-size: 2rem;
            ">{block?.title?.[0]}</span
            >
          </div>
        {/if}
      </div>
      <div class="p-4">
        <h2
          class="text-center line-clamp-2"
          style="
          font-weight: var(--titleFontWeight);
          font-size: var(--titleFontSize);
        "
        >
          {block.title}
        </h2>
      </div>
    </div>
  </a>
</div>
