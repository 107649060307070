<script>
  import { createEventDispatcher, onMount } from "svelte";
  import Modal from "$components/Modal.svelte";
  import { getBlockStyle, getBorderRadius } from "$utils/block-style";
  import { useHoverEffect } from "$utils/block-hover";

  export let block;
  export let theme;
  export let buttonStyle;
  export let hoverEffect;

  const dispatch = createEventDispatcher();

  let transitionDuration = "200ms";

  $: url = block.url;
  $: currentButtonStyle = getBlockStyle(theme, buttonStyle, transitionDuration);
  $: blockRadius = getBorderRadius(buttonStyle);

  let isModalOpen = false;

  function openModal() {
    isModalOpen = true;
    dispatch("block-click", { block_id: block.id });
  }

  onMount(() => {
    dispatch("mounted");
  });
</script>

{#if url}
  <div
    class="overflow-hidden transition-all ease-in-out w-full"
    style={currentButtonStyle}
    use:useHoverEffect={{
      theme,
      buttonStyle,
      hoverEffect,
      transitionDuration,
      getBlockStyle,
    }}
  >
    <button
      on:click={openModal}
      class="flex items-center p-4 transition-colors ease-in-out w-full"
      style="
        color: {buttonStyle && buttonStyle.startsWith('fill')
        ? 'var(--buttonTextColor)'
        : 'var(--linkColor)'};
        transition-duration: {transitionDuration};
      "
    >
      <div
        class="w-12 h-12 mr-4 flex items-center justify-center flex-shrink-0"
        style="
          background-color: var(--imagePlaceholderColor);
          border-radius: {blockRadius};
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="w-6 h-6"
          style="color: var(--imagePlaceholderTextColor);"
        >
          <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
          <line x1="16" y1="2" x2="16" y2="6"></line>
          <line x1="8" y1="2" x2="8" y2="6"></line>
          <line x1="3" y1="10" x2="21" y2="10"></line>
        </svg>
      </div>
      <div class="flex-grow text-left">
        <h2
          class="line-clamp-2"
          style="
          font-weight: var(--titleFontWeight);
          font-size: var(--titleFontSize);
        "
        >
          {block.title}
        </h2>
      </div>
    </button>
  </div>
  <Modal bind:isOpen={isModalOpen} size="xl" removePadding>
    <iframe
      src={url}
      width="100%"
      height="600"
      frameborder="0"
      class="mt-12 border-t"
      title="Calendly Scheduling Page"
    ></iframe>
  </Modal>
{/if}
