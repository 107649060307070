<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, fly } from "svelte/transition";

  const SLOTS = $$props.$$slots;

  export let isOpen = false;
  export let size = "md"; // 'sm', 'md', 'lg', 'xl', 'full', '2x', '3x', '4x'
  export let closeOnEscape = true;
  export let closeOnOutsideClick = true;
  export let showBackButton = false;
  export let removePadding = false; // New option to remove padding

  const dispatch = createEventDispatcher();

  const sizes = {
    sm: "max-w-[90%] sm:max-w-sm",
    md: "max-w-[95%] sm:max-w-md",
    lg: "max-w-[95%] sm:max-w-lg",
    xl: "max-w-[95%] sm:max-w-xl",
    full: "max-w-full",
    "2xl": "max-w-[95%] sm:max-w-2xl",
    "3xl": "max-w-[95%] sm:max-w-3xl",
    "4xl": "max-w-[95%] sm:max-w-4xl",
  };

  function close() {
    isOpen = false;
    dispatch("close");
  }

  function handleKeydown(event) {
    if (closeOnEscape && event.key === "Escape") {
      close();
    }
  }

  function handleOutsideClick(event) {
    if (closeOnOutsideClick && event.target === event.currentTarget) {
      close();
    }
  }

  function handleBack() {
    dispatch("back");
  }

  function disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  function enableBodyScroll() {
    document.body.style.overflow = "";
  }

  onMount(() => {
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      enableBodyScroll();
    };
  });

  $: if (isOpen) {
    disableBodyScroll();
  } else {
    enableBodyScroll();
  }
</script>

{#if isOpen}
  <div
    class="relative z-[20]"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      transition:fade={{ duration: 300 }}
      class="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"
      aria-hidden="true"
    ></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div
        class="flex min-h-full items-center justify-center p-4 text-center"
        on:click={handleOutsideClick}
      >
        <div
          transition:fly={{ y: 20, duration: 300 }}
          class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full {sizes[
            size
          ]} {removePadding ? '' : 'px-4 pb-4 pt-5 sm:p-6'}"
        >
          <div class="absolute top-0 right-0 pt-3 pr-3">
            <button
              type="button"
              class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              on:click={close}
            >
              <span class="sr-only">Close</span>
              <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {#if showBackButton}
            <div class="absolute top-0 left-0 pt-4 pl-4">
              <button
                type="button"
                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                on:click={handleBack}
              >
                {#if SLOTS.backButton}
                  <slot name="backButton">
                    <span class="sr-only">Back</span>
                    <svg
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>
                  </slot>
                {:else}
                  <span class="sr-only">Back</span>
                  <svg
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                {/if}
              </button>
            </div>
          {/if}
          {#if SLOTS.header}
            <div class={removePadding ? "p-4" : ""}>
              <slot name="header">
                <h3
                  class="text-lg sm:text-xl font-semibold leading-6 text-gray-900"
                  id="modal-title"
                >
                  Modal Title
                </h3>
              </slot>
            </div>
          {/if}
          <div class={removePadding ? "" : "mt-3"}>
            <slot>
              <p class="text-sm sm:text-base text-gray-500">
                Modal content goes here.
              </p>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
