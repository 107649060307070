<script>
  import { createEventDispatcher } from "svelte";
  import { fade, slide } from "svelte/transition";

  import knownSocialLinks from "$utils/social_links";

  import Input from "$components/Input.svelte";
  import Button from "$components/Button.svelte";
  import Modal from "$components/Modal.svelte";

  export let socialLinks = [];

  const dispatch = createEventDispatcher();

  let showModal = false;
  let newSocialLink = "";
  let editingIndex = -1;

  function handleAddSocialLink() {
    if (newSocialLink) {
      if (editingIndex === -1) {
        socialLinks = [...socialLinks, { url: newSocialLink }];
      } else {
        socialLinks[editingIndex] = { url: newSocialLink };
        editingIndex = -1;
      }
      newSocialLink = "";
      showModal = false;
      dispatchUpdate();
    }
  }

  function handleRemoveSocialLink(index) {
    socialLinks = socialLinks.filter((_, i) => i !== index);
    dispatchUpdate();
  }

  function handleEditSocialLink(index) {
    editingIndex = index;
    newSocialLink = socialLinks[index].url;
    showModal = true;
  }

  function dispatchUpdate() {
    dispatch("update", { socialLinks });
  }

  function getSocialLinkInfo(url) {
    const lowerUrl = url.toLowerCase();
    for (const [platform, info] of Object.entries(knownSocialLinks)) {
      if (lowerUrl.includes(platform)) {
        return {
          icon: info.icon,
          displayText: url,
          color: info.color,
        };
      }
    }
    return { icon: "fas fa-globe", displayText: url, color: "#718096" };
  }
</script>

<div>
  <h4 class="text-lg font-bold text-gray-800 mb-8">Social Links</h4>
  <div class="grid grid-cols-1 gap-4">
    {#each socialLinks as link, index}
      <div
        in:fade={{ duration: 300 }}
        out:slide={{ duration: 300 }}
        class="relative bg-gray-50 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-200 w-full"
        style="border-left: 4px solid {getSocialLinkInfo(link.url).color}">
        <div class="flex items-center">
          <i
            class="{getSocialLinkInfo(link.url).icon} text-2xl mr-4"
            style="color: {getSocialLinkInfo(link.url).color}"></i>
          <a
            href={link.url}
            target="_blank"
            class="text-blue-600 hover:underline font-medium flex-grow overflow-hidden whitespace-nowrap text-ellipsis"
            style="max-width: calc(100% - 6rem);">
            {getSocialLinkInfo(link.url).displayText}
          </a>
          <div class="flex space-x-4 ml-2">
            <button
              class="text-gray-500 hover:text-blue-500 transition-colors duration-200 w-5 h-8 flex items-center justify-center"
              on:click={() => handleEditSocialLink(index)}>
              <i class="fas fa-edit"></i>
            </button>
            <button
              class="text-gray-500 hover:text-red-500 transition-colors duration-200 w-5 h-8 flex items-center justify-center"
              on:click={() => handleRemoveSocialLink(index)}>
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    {/each}
    <div
      class="flex items-center justify-center bg-gray-100 rounded-lg p-4 cursor-pointer hover:bg-gray-200 transition-colors duration-200 w-full"
      on:click={() => {
        editingIndex = -1;
        newSocialLink = "";
        showModal = true;
      }}>
      <i class="fas fa-plus text-2xl text-gray-500 mr-2"></i>
      <span class="text-gray-700 font-medium">Add Social Link</span>
    </div>
  </div>
</div>

<Modal bind:isOpen={showModal}>
  <h3 class="text-2xl font-bold text-gray-800 mb-6">
    {editingIndex === -1 ? "Add" : "Edit"} Social Link
  </h3>
  <form on:submit|preventDefault={handleAddSocialLink} class="space-y-4">
    <Input
      type="url"
      label="Social Link URL"
      bind:value={newSocialLink}
      placeholder="Enter the URL (e.g., https://twitter.com/username)"
      required />
    <div class="flex justify-end space-x-3">
      <Button
        type="button"
        variant="secondary"
        on:click={() => {
          showModal = false;
          editingIndex = -1;
          newSocialLink = "";
        }}>
        Cancel
      </Button>
      <Button type="submit" variant="primary">
        {editingIndex === -1 ? "Add" : "Update"}
      </Button>
    </div>
  </form>
</Modal>
