<script>
  import { createEventDispatcher } from "svelte";
  import Button from "$components/Button.svelte";
  import Input from "$components/Input.svelte";
  import Textarea from "$components/Textarea.svelte";
  import { notify } from "$utils/notify";
  import Ajax from "$utils/ajax";
  import Modal from "$components/Modal.svelte";
  import { currencies, formatCurrency } from "$utils/currency";
  import Sortable, {
    AutoScroll,
  } from "sortablejs/modular/sortable.core.esm.js";

  const dispatch = createEventDispatcher();

  export let block = null;

  let step = block ? 2 : 1;
  let productUrl = "";
  let productTitle = block?.title || "";
  let productDescription = block?.fields?.description || "";
  let productPrice = block?.fields?.price
    ? formatCurrency(block.fields.price, false)
    : "";
  let productCurrency = block?.fields?.currency || "USD";
  let productImages = block?.fields?.images || [];
  let buyLink = block?.fields?.buy_link || "";
  let isLoading = false;
  let showImageModal = false;
  let newImageUrl = "";
  let saving = false;
  let errors = {};

  let setupSortable = function (node) {
    try {
      Sortable.mount(new AutoScroll());
    } catch {}

    Sortable.create(node, {
      animation: 150,
      scroll: true,
      forceFallback: true,
      onEnd: (evt) => {
        const newOrder = Array.from(node.children).map(
          (child) => child.dataset.src
        );

        productImages = [...newOrder];
      },
    });
  };

  async function importProduct() {
    isLoading = true;
    const [error, data] = await Ajax.get(
      `/dashboard/embed/product_info?url=${encodeURIComponent(productUrl)}`
    );
    isLoading = false;

    if (error) {
      notify({
        type: "error",
        text: "Failed to import product. Please try again.",
      });
    } else {
      productTitle = data.title;
      productDescription = data.description;
      productPrice = data.price ? formatCurrency(data.price, false) : "";
      productCurrency = data.currency || "USD";
      productImages = data.image_urls || [];
      buyLink = productUrl; // Use the original URL as the buy link
      step = 2;
    }
  }

  function addImageField() {
    showImageModal = true;
  }

  function removeImageField(index) {
    productImages = productImages.filter((_, i) => i !== index);
  }

  async function saveProduct(event) {
    event.preventDefault();
    if (saving) return;
    saving = true;

    let sharedData = {
      block_type: "product",
      title: productTitle,
      fields: {
        description: productDescription,
        buy_link: buyLink,
        price: productPrice,
        currency: productCurrency,
        images: productImages,
      },
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, sharedData)
      : await Ajax.post("/dashboard/blocks", sharedData);

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  }

  function handleCancel() {
    dispatch("close");
  }

  async function addImage() {
    if (newImageUrl.trim() !== "") {
      if (productImages.includes(newImageUrl.trim())) {
        notify({
          type: "error",
          message: "This image has already been added.",
        });
        return;
      }

      try {
        const img = new Image();
        img.onload = () => {
          productImages = [...productImages, newImageUrl.trim()];
          newImageUrl = "";
          showImageModal = false;
        };
        img.onerror = () => {
          notify({
            type: "error",
            message: "Invalid image URL. Please enter a valid image URL.",
          });
        };
        img.src = newImageUrl.trim();
      } catch (error) {
        notify({
          type: "error",
          message:
            "An error occurred while validating the image. Please try again.",
        });
      }
    }
  }
</script>

<div class="space-y-6">
  <h2 class="text-xl font-bold mb-12 mt-4 text-center">
    {block ? "Edit" : "Add"} a product
  </h2>

  {#if !block && step === 1}
    <div class="bg-white">
      <h3
        class="text-sm text-gray-700 mb-8 bg-purple-100 border-l-4 border-purple-500 p-4 rounded-r-lg leading-relaxed"
      >
        Enter a product URL to auto-import details. You can skip this step and
        add the details manually later.
      </h3>
      <Input
        label="Product URL (Optional)"
        placeholder="Enter product URL to auto-import details"
        bind:value={productUrl}
        klass="mb-10  "
      />
      <div class="space-y-3">
        <Button
          on:click={importProduct}
          variant="primary"
          klass="w-full py-3"
          disabled={isLoading}
        >
          {#if isLoading}
            <span class="inline-block animate-spin mr-2">&#8635;</span>
          {/if}
          {isLoading ? "Importing..." : "Import Product"}
        </Button>
        <Button
          on:click={() => (step = 2)}
          variant="secondary"
          klass="w-full py-3"
        >
          Skip Import
        </Button>
      </div>
    </div>
  {:else}
    <Input
      label="Product Title"
      placeholder="Enter product title"
      bind:value={productTitle}
    />

    <Textarea
      label="Product Description"
      placeholder="Describe your product"
      bind:value={productDescription}
    />

    <div class="flex space-x-4">
      <Input
        label="Product Price"
        type="number"
        placeholder="Enter product price"
        bind:value={productPrice}
        klass="flex-grow"
      />
      <div class="w-1/3">
        <label for="currency" class="block text-sm font-medium text-gray-700"
          >Currency</label
        >
        <select
          class="mt-3 block w-full rounded-lg text-zinc-900 focus:ring-0 sm:text-sm sm:leading-6 border-zinc-300 focus:border-zinc-400"
          id="currency"
          bind:value={productCurrency}
        >
          {#each currencies as currency}
            <option value={currency.code}
              >{currency.code} ({currency.symbol})</option
            >
          {/each}
        </select>
      </div>
    </div>

    <div class="space-y-4">
      <label class="block text-sm font-medium text-gray-700"
        >Product Images (drag to reorder)</label
      >
      <div use:setupSortable class="flex flex-wrap gap-4">
        {#each productImages as image, index (image)}
          <div class="relative w-32 h-32" data-src={image}>
            <img
              src={image}
              alt="Product"
              class="w-full h-full object-cover rounded-lg border border-gray-200 cursor-move"
            />
            <button
              on:click={() => removeImageField(index)}
              class="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-opacity-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        {/each}
      </div>
      <Button on:click={addImageField} variant="secondary"
        >Add Another Image</Button
      >
    </div>

    <Input
      label="Buy Link"
      placeholder="Enter the URL where customers can purchase"
      bind:value={buyLink}
    />

    <div class="flex justify-end space-x-4">
      <Button on:click={handleCancel} variant="secondary">Cancel</Button>
      <Button on:click={saveProduct} variant="primary" disabled={saving}>
        {#if saving}
          <span class="inline-block animate-spin mr-2">&#8635;</span>
        {/if}
        {saving ? "Saving..." : block ? "Update Product" : "Create Product"}
      </Button>
    </div>
  {/if}
</div>

<Modal bind:isOpen={showImageModal}>
  <h2 slot="header">Add Image URL</h2>
  <div class="py-4">
    <Input
      label="Image URL"
      placeholder="Enter the URL of the image"
      bind:value={newImageUrl}
    />
  </div>
  <div class="flex justify-end space-x-2">
    <Button on:click={() => (showImageModal = false)} variant="secondary"
      >Cancel</Button
    >
    <Button on:click={addImage} variant="primary">Add Image</Button>
  </div>
</Modal>
