<script>
  import { createEventDispatcher } from "svelte";
  import { notify } from "$utils/notify";

  import { cropper } from "$utils/cropper";
  import { showUploader } from "$utils/upload";

  import Button from "$components/Button.svelte";
  import Form from "$components/Form.svelte";
  import Toggle from "$components/Toggle.svelte";
  import Input from "$components/Input.svelte";

  import Ajax from "$utils/ajax";

  const dispatch = createEventDispatcher();

  export let block = null;

  let saving = false;
  let fileInput;
  let isUploading = false;
  let uploadedImageUrl = block ? block.image_url : "";
  let showOnlyImage = block?.fields?.show_only_image || false;
  let imageSize = block?.fields?.size || "100%";

  const saveImage = async (event) => {
    event.preventDefault();
    if (saving) return;
    saving = true;

    let sharedData = {
      image_url: uploadedImageUrl,
      block_type: "image",
      fields: {
        show_only_image: showOnlyImage,
        size: imageSize,
      },
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, sharedData)
      : await Ajax.post("/dashboard/blocks", sharedData);

    if (ajaxErrors) {
      notify({
        type: "error",
        text: block ? "Unable to update image" : "Unable to add image",
      });
    } else {
      if (!block) {
        uploadedImageUrl = "";
      }

      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }

      dispatch("close");
    }

    saving = false;
  };

  async function handleFileSelected(event) {
    isUploading = true;

    try {
      const uploadedUrl = await showUploader({
        fileType: "image",
        crop: cropper.crop,
      });
      uploadedImageUrl = uploadedUrl;
    } catch (error) {
      notify({
        message: "Upload failed. Please try again.",
        type: "error",
      });
    } finally {
      isUploading = false;
    }
  }

  function clearImage() {
    uploadedImageUrl = "";
  }
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit Image" : "Add Image"}
</h2>

<Form on:submit={saveImage}>
  <div class="flex flex-col gap-8">
    <div class="relative">
      <Button
        type="button"
        klass="w-full h-12 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
        on:click={handleFileSelected}
      >
        Upload Image
      </Button>
    </div>

    <div class="w-full h-64 bg-gray-100 rounded-lg overflow-hidden relative">
      {#if uploadedImageUrl}
        <img
          src={uploadedImageUrl}
          class="w-full h-full object-contain"
          alt="Uploaded image"
        />
        <Button
          type="button"
          klass="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
          on:click={clearImage}
        >
          Clear
        </Button>
      {:else}
        <div class="flex items-center justify-center h-full text-gray-400">
          <span>No image uploaded</span>
        </div>
      {/if}
    </div>
    <div class="flex items-center">
      <Toggle bind:checked={showOnlyImage} label="Show only image" />
    </div>
    <div class="flex flex-row gap-4">
      <label class="block flex-1">
        <Input
          type="text"
          bind:value={imageSize}
          label="Size"
          klass="-mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
          placeholder="Enter image size"
        />
      </label>
    </div>
    <Button
      type="submit"
      klass="w-full h-12"
      disabled={saving || isUploading || !uploadedImageUrl}
    >
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update Image" : "Add Image"}
    </Button>
  </div>
</Form>
