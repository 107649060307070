<script>
  import Dashboard from "$layouts/Dashboard.svelte";

  import Seo from "./settings/Seo.svelte";
  import AnalyticsIntegration from "./settings/AnalyticsIntegration.svelte";
  import Subscribe from "./settings/Subscribe.svelte";
  import SharePage from "./settings/SharePage.svelte";

  import { writable } from "svelte/store";

  export let currentUser;

  let activeTab = writable("seo");

  const tabs = [
    { id: "seo", label: "Search Engine Optimization" },
    { id: "analytics", label: "Analytics Integration" },
    { id: "subscribe", label: "Subscribe" },
    { id: "share", label: "Share Page" },
  ];

  let updater = function (fn) {
    const data = fn({ currentUser });

    if (data.currentUser) {
      currentUser = data.currentUser;
    }
  };

  function setActiveTab(tabId) {
    activeTab.set(tabId);
  }
</script>

<Dashboard>
  <div class="flex">
    <aside class="w-64 bg-white shadow-md">
      <nav class="py-6">
        <h2 class="text-xl font-semibold text-gray-800 px-6 mb-4">Settings</h2>
        <ul>
          {#each tabs as tab}
            <li>
              <button
                class="w-full text-left py-3 px-6 transition-colors duration-200 {$activeTab ===
                tab.id
                  ? 'bg-purple-50 text-purple-700 border-l-4 border-purple-700'
                  : 'text-gray-600 hover:bg-gray-100'}"
                on:click={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            </li>
          {/each}
        </ul>
      </nav>
    </aside>
    <main class="flex-1 p-8 bg-gray-50">
      {#if $activeTab === "seo"}
        <Seo {currentUser} {updater} />
      {:else if $activeTab === "analytics"}
        <AnalyticsIntegration {currentUser} {updater} />
      {:else if $activeTab === "subscribe"}
        <Subscribe {currentUser} {updater} />
      {:else if $activeTab === "share"}
        <SharePage {currentUser} {updater} />
      {/if}
    </main>
  </div>
</Dashboard>
