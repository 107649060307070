<script>
  import { createEventDispatcher } from "svelte";

  import { notify } from "$utils/notify";
  import Ajax from "$utils/ajax";

  export let currentUser;

  let metaTitle = currentUser.meta_title || "";
  let metaDescription = currentUser.meta_description || "";
  let isSaving = false;
  let errors = {};

  const dispatch = createEventDispatcher();

  async function handleSubmit() {
    if (isSaving) return;
    isSaving = true;
    errors = {};

    const data = {
      meta_title: metaTitle,
      meta_description: metaDescription,
    };

    const [ajaxErrors, resp] = await Ajax.post(
      "/dashboard/settings/save_seo",
      data
    );

    if (ajaxErrors) {
      errors = ajaxErrors;
      notify({
        message:
          "Failed to update SEO settings. Please check the errors and try again.",
        type: "error",
      });
    } else {
      notify({
        message: "SEO settings saved successfully",
        type: "success",
      });

      dispatch("saved", { metaTitle, metaDescription });

      currentUser = {
        ...currentUser,
        meta_title: metaTitle,
        meta_description: metaDescription,
      };
    }

    isSaving = false;
  }
</script>

<div class="w-full p-8 bg-white rounded-lg shadow-md">
  <h2 class="text-2xl font-bold mb-5">SEO Settings</h2>
  <p class="text-gray-600 mb-12">
    Optimize your page's visibility in search engines by customizing your meta
    title and description.
  </p>

  <form on:submit|preventDefault={handleSubmit} class="space-y-6">
    <div>
      <label
        for="metaTitle"
        class="block text-sm font-medium text-gray-700 mb-1"
      >
        Meta Title
      </label>
      <input
        type="text"
        id="metaTitle"
        bind:value={metaTitle}
        class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 {errors.meta_title
          ? 'border-red-500'
          : ''}"
        placeholder="Enter your page title (50-60 characters recommended)"
        maxlength="60"
      />
      <p class="text-sm text-gray-500 mt-1">
        Characters: {metaTitle.length}/60
      </p>
      {#if errors.meta_title}
        <p class="text-sm text-red-600 mt-1">{errors.meta_title}</p>
      {/if}
    </div>

    <div>
      <label
        for="metaDescription"
        class="block text-sm font-medium text-gray-700 mb-1"
      >
        Meta Description
      </label>
      <textarea
        id="metaDescription"
        bind:value={metaDescription}
        rows="4"
        class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 {errors.meta_description
          ? 'border-red-500'
          : ''}"
        placeholder="Enter a brief description of your page (150-160 characters recommended)"
        maxlength="160"
      ></textarea>
      <p class="text-sm text-gray-500 mt-1">
        Characters: {metaDescription.length}/160
      </p>
      {#if errors.meta_description}
        <p class="text-sm text-red-600 mt-1">{errors.meta_description}</p>
      {/if}
    </div>

    <div class="flex items-center justify-between">
      <button
        type="submit"
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isSaving}
      >
        {#if isSaving}
          <span class="inline-flex items-center">
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Saving...
          </span>
        {:else}
          Save settings
        {/if}
      </button>
    </div>
  </form>

  <div class="mt-12 bg-gray-100 p-6 rounded-lg">
    <h3 class="text-lg font-semibold mb-4">Preview</h3>
    <div class="bg-white p-4 rounded border border-gray-300">
      <p class="text-blue-600 text-xl mb-1 truncate">
        {metaTitle || "Your Page Title"}
      </p>
      <p class="text-green-700 text-sm mb-2">
        {currentUser.url}
      </p>
      <p class="text-gray-600 text-sm line-clamp-2">
        {metaDescription ||
          "Your page description will appear here. Make it compelling to improve click-through rates from search engine results."}
      </p>
    </div>
  </div>
</div>
