<script>
  import Button from "$components/Button.svelte";
  import Card from "$components/Card.svelte";
  import Modal from "$components/Modal.svelte";
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";

  import blockTypes from "./_blocks.js";
  import { notify } from "$utils/notify";

  export let isOpen = false;
  export let updater;

  let view = "options";
  let saving = false;

  let selectedBlockType = null;
  let BlockComponent;
  let activeSection = "Content";
  let tabsContainer;

  const sections = {
    Content: ["link", "heading", "image", "text", "video", "youtube"],
    Monetization: ["product"],
    Integrations: ["calendly"],
  };

  const closeModal = () => {
    isOpen = false;
    reset();
  };

  const selectBlockType = (type) => {
    selectedBlockType = type;
    view = "form";
    BlockComponent = blockTypes[type].addEditComponent;
  };

  const showOptions = () => {
    view = "options";
    selectedBlockType = null;
  };

  const reset = () => {
    view = "options";
    saving = false;
    selectedBlockType = null;
    BlockComponent = null;
    activeSection = "Content";
  };

  const handleAddedBlock = (event) => {
    updater(({ blocks }) => {
      const newBlocks = [...blocks, event.detail];
      return { blocks: newBlocks };
    });

    notify({
      message: "Block added successfully",
      type: "success",
      duration: 1000,
    });

    setTimeout(() => {
      const newBlockElement = document.getElementById(
        `block-${event.detail.id}`
      );
      if (newBlockElement) {
        newBlockElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 50);

    closeModal();
  };

  const setActiveSection = (sectionName) => {
    activeSection = sectionName;
    if (tabsContainer) {
      const activeTab = tabsContainer.querySelector(
        `[data-section="${sectionName}"]`
      );
      if (activeTab) {
        activeTab.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }
    }
  };

  onMount(() => {
    if (tabsContainer) {
      const activeTab = tabsContainer.querySelector(
        `[data-section="${activeSection}"]`
      );
      if (activeTab) {
        activeTab.scrollIntoView({ inline: "center", block: "nearest" });
      }
    }
  });

  $: if (!isOpen) {
    reset();
  }
</script>

<style>
  .add-block-section-tabs {
    overflow-x: auto;
    scrollbar-width: none;
  }

  .add-block-section-tabs::-webkit-scrollbar {
    display: none;
  }

  .block-types-container {
    max-height: calc(100vh - 250px);
    min-height: calc(100vh - 350px);
    overflow-y: auto;
  }
</style>

<Button
  klass="rounded-lg py-2 px-3 text-sm font-semibold leading-6 active:text-white/80 bg-zinc-900 hover:bg-zinc-700 text-white w-full !bg-purple-600 hover:!bg-purple-800 text-white py-3 md:py-4 px-4 flex items-center justify-center"
  on:click={() => (isOpen = true)}>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-4 w-4 md:h-5 md:w-5 mr-2"
    viewBox="0 0 20 20"
    fill="currentColor">
    <path
      fill-rule="evenodd"
      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
      clip-rule="evenodd"></path>
  </svg>
  Add Block
</Button>

<Modal
  bind:isOpen
  size="2xl"
  showBackButton={view === "form"}
  closeOnOutsideClick={false}
  on:back={showOptions}>
  <svelte:fragment slot="backButton">
    <span class="flex items-center">
      <svg
        class="h-4 w-4 md:h-5 md:w-5 mr-2 md:mr-3"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 19l-7-7m0 0l7-7m-7 7h18" />
      </svg>
      Back
    </span>
  </svelte:fragment>
  <Card size="full" shadow={false}>
    <svelte:fragment slot="content">
      <div class="overflow-y-hidden">
        {#if view === "options"}
          <div class="text-center mb-8 md:mb-8">
            <h2 class="text-xl md:text-2xl font-bold text-gray-800">
              Add a block
            </h2>
          </div>
          <div class="mb-6 md:mb-8">
            <ul
              class="flex overflow-x-auto pb-0 border-b overflow-y-hidden add-block-section-tabs"
              bind:this={tabsContainer}>
              {#each Object.keys(sections) as sectionName}
                <li class="flex-shrink-0 -mb-px mr-1">
                  <button
                    data-section={sectionName}
                    class="bg-white inline-block py-2 px-4 text-sm md:text-base font-semibold whitespace-nowrap {activeSection ===
                    sectionName
                      ? 'text-purple-600 border-b-2 border-purple-600'
                      : 'text-gray-500 hover:text-gray-800'}"
                    on:click={() => setActiveSection(sectionName)}
                    >{sectionName}</button>
                </li>
              {/each}
            </ul>
          </div>
          <div class="block-types-container">
            {#each Object.entries(sections) as [sectionName, sectionTypes]}
              <div
                id={sectionName}
                class="mb-6 md:mb-8 transition-opacity duration-300 ease-in-out"
                class:hidden={activeSection !== sectionName}
                class:block={activeSection === sectionName}>
                {#if activeSection === sectionName}
                  <div
                    class="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6"
                    in:fade={{ duration: 100 }}>
                    {#each sectionTypes as type}
                      {#if blockTypes[type]}
                        <button
                          class="p-4 md:p-6 bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center justify-center group"
                          on:click={() => selectBlockType(type)}>
                          <div
                            class="text-purple-500 group-hover:text-purple-700 transition-colors duration-300">
                            {@html blockTypes[type].icon}
                          </div>
                          <span
                            class="mt-2 md:mt-4 text-sm md:text-base font-medium text-gray-800 group-hover:text-purple-700"
                            >{blockTypes[type].label}</span>
                        </button>
                      {/if}
                    {/each}
                  </div>
                {/if}
              </div>
            {/each}
          </div>
        {:else if view === "form" && BlockComponent}
          <svelte:component
            this={BlockComponent}
            on:close={closeModal}
            on:added-block={handleAddedBlock} />
        {/if}
      </div>
    </svelte:fragment>
  </Card>
</Modal>
