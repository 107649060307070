<script>
  import { fade, fly } from "svelte/transition";
  import Dashboard from "$layouts/Dashboard.svelte";

  import IphonePreview from "./IphonePreview.svelte";
  import ProfileSettings from "./appearance/ProfileSettings.svelte";
  import LayoutSettings from "./appearance/LayoutSettings.svelte";
  import ThemeSelector from "./appearance/ThemeSelector.svelte";
  import CustomizeSettings from "./appearance/CustomizeSettings.svelte";

  import { getThemeFromId } from "$utils/themes";
  import { notify } from "$utils/notify";
  import { alertErrors } from "$utils/form.js";
  import Ajax from "$utils/ajax";

  export let blocks;
  export let currentUser;
  export let socialLinks;

  let activeTab = "Profile";
  let selectedTheme = getThemeFromId(currentUser.theme);
  let selectedButtonStyle = currentUser.button_style;
  let selectedHoverEffect = currentUser.hover_effect;
  let selectedLayoutStyle = currentUser.layout_style || "list";
  let selectedFont = currentUser.font || "Arial";

  let profileImageUrl = currentUser.profile_image_url;
  let profileName = currentUser.profile_name;
  let bio = currentUser.bio;
  let saving = false;
  let originalSocialLinks = JSON.parse(JSON.stringify(socialLinks));
  let isPreviewModalOpen = false;

  const handleThemeChanged = (event) => {
    selectedTheme = event.detail.theme;
  };

  const handleButtonStyleChanged = (event) => {
    selectedButtonStyle = event.detail.style;
  };

  const handleHoverEffectChanged = (event) => {
    selectedHoverEffect = event.detail.effect;
  };

  const handleLayoutStyleChanged = (event) => {
    selectedLayoutStyle = event.detail.layoutStyle;
  };

  const handleFontUpdate = (event) => {
    selectedFont = event.detail.font;
  };

  const handleProfileUpdate = (event) => {
    let data = event.detail;

    profileImageUrl = data.profileImageUrl;
    profileName = data.profileName;
    bio = data.bio;
    socialLinks = data.socialLinks || [];
  };

  const handleAvatarUpdate = async (event) => {
    let [errors, _resp] = await Ajax.put("/dashboard/appearance/me", {
      profile_image_url: event.detail.profileImageUrl,
    });

    if (errors) {
      notify({ message: alertErrors(errors), type: "error" });
    } else {
      notify({
        message: "Profile picture updated",
        type: "success",
        duration: 2000,
      });
    }
  };

  const handleSocialLinksSave = async () => {
    if (JSON.stringify(socialLinks) !== JSON.stringify(originalSocialLinks)) {
      const data = {
        social_links: socialLinks.map((link) => ({ url: link.url })),
      };

      const [errors, _resp] = await Ajax.post(
        "/dashboard/appearance/update_social_links",
        data
      );

      if (errors) {
        notify({ message: alertErrors(errors), type: "error" });
      } else {
        originalSocialLinks = JSON.parse(JSON.stringify(socialLinks));
      }
    }
  };

  const handleSave = async () => {
    if (saving) return;
    saving = true;

    let data = {
      theme: selectedTheme.id,
      button_style: selectedButtonStyle,
      hover_effect: selectedHoverEffect,
      layout_style: selectedLayoutStyle,
      profile_name: profileName,
      bio: bio,
      profile_image_url: profileImageUrl,
      layout_style: selectedLayoutStyle,
      font: selectedFont,
    };

    const [errors, resp] = await Ajax.put("/dashboard/appearance/me", data);

    if (errors) {
      notify({ message: alertErrors(errors), type: "error" });
    } else {
      notify({
        message: "Appearance updated",
        type: "success",
        duration: 1000,
      });
    }

    await handleSocialLinksSave();

    saving = false;
  };

  const togglePreviewModal = () => {
    isPreviewModalOpen = !isPreviewModalOpen;
  };
</script>

<Dashboard>
  <div class="flex flex-col lg:flex-row gap-4 sm:gap-8 -mt-10 pb-20">
    <div class="w-full lg:w-3/5 p-4 pl-2">
      <div class="mb-5 mt-4 -ml-1">
        <div
          class="flex flex-col sm:flex-row justify-between items-center bg-gray-100 rounded-lg p-2"
        >
          <div
            class="flex flex-wrap justify-center sm:justify-start mb-2 sm:mb-0"
          >
            <button
              class={`py-2 px-4 rounded-md mb-2 sm:mb-0 ${activeTab === "Profile" ? "bg-white shadow-md" : "hover:bg-gray-200"}`}
              on:click={() => (activeTab = "Profile")}
            >
              Profile
            </button>
            <button
              class={`py-2 px-4 rounded-md mb-2 sm:mb-0 sm:ml-2 ${activeTab === "Layout" ? "bg-white shadow-md" : "hover:bg-gray-200"}`}
              on:click={() => (activeTab = "Layout")}
            >
              Layout
            </button>
            <button
              class={`py-2 px-4 rounded-md mb-2 sm:mb-0 sm:ml-2 ${activeTab === "Themes" ? "bg-white shadow-md" : "hover:bg-gray-200"}`}
              on:click={() => (activeTab = "Themes")}
            >
              Themes
            </button>
            <button
              class={`py-2 px-4 rounded-md mb-2 sm:mb-0 sm:ml-2 ${activeTab === "Customize" ? "bg-white shadow-md" : "hover:bg-gray-200"}`}
              on:click={() => (activeTab = "Customize")}
            >
              Customize
            </button>
          </div>
          <button
            class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md w-full sm:w-auto flex items-center justify-center"
            on:click={handleSave}
            disabled={saving}
          >
            {#if saving}
              <svg
                class="animate-spin h-5 w-5 mr-3 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
              Saving...
            {:else}
              Save Changes
            {/if}
          </button>
        </div>
      </div>

      <div class="mt-6 space-y-4">
        <div class={activeTab === "Profile" ? "" : "hidden"}>
          <ProfileSettings
            user={currentUser}
            {socialLinks}
            on:update={handleProfileUpdate}
            on:update-avatar={handleAvatarUpdate}
          />
        </div>
        <div class={activeTab === "Layout" ? "" : "hidden"}>
          <LayoutSettings
            on:layout-update={handleLayoutStyleChanged}
            on:font-update={handleFontUpdate}
            {selectedLayoutStyle}
            {selectedFont}
            {currentUser}
            {blocks}
          />
        </div>
        <div class={activeTab === "Themes" ? "" : "hidden"}>
          <ThemeSelector
            on:theme-changed={handleThemeChanged}
            {selectedTheme}
          />
        </div>
        <div class={activeTab === "Customize" ? "" : "hidden"}>
          <CustomizeSettings
            on:button-style-changed={handleButtonStyleChanged}
            on:hover-effect-changed={handleHoverEffectChanged}
            {selectedButtonStyle}
            {selectedHoverEffect}
          />
        </div>
      </div>
    </div>
    <div
      class="hidden lg:block p-4 lg:fixed lg:top-6 lg:right-0 lg:mr-4 mt-8 lg:mt-0"
    >
      {#key selectedLayoutStyle}
        <IphonePreview
          {blocks}
          {socialLinks}
          user={{
            ...currentUser,
            profile_image_url: profileImageUrl,
            profile_name: profileName,
            bio: bio,
          }}
          theme={selectedTheme.theme}
          buttonStyle={selectedButtonStyle}
          hoverEffect={selectedHoverEffect}
          layoutStyle={selectedLayoutStyle}
          font={selectedFont}
        />
      {/key}
    </div>
  </div>

  <!-- Mobile preview button -->
  <div class="lg:hidden fixed bottom-6 left-0 right-0 z-1 flex justify-center">
    <button
      on:click={togglePreviewModal}
      class="px-6 py-3 rounded-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-300 ease-in-out transform hover:scale-105 flex items-center space-x-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
        />
      </svg>
      <span class="font-semibold">Preview</span>
    </button>
  </div>

  <!-- Mobile preview modal -->
  {#if isPreviewModalOpen}
    <div
      transition:fade={{ duration: 200 }}
      class="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
    >
      <div
        transition:fly={{ y: 200, duration: 300, easing: (t) => t * (2 - t) }}
        class="bg-white rounded-2xl w-full max-w-md shadow-xl flex flex-col max-h-[90vh]"
      >
        <div class="p-4 flex-grow flex flex-col overflow-hidden">
          <div
            class="flex-grow flex items-center justify-center overflow-hidden"
          >
            <div class="transform">
              <IphonePreview
                {blocks}
                {socialLinks}
                user={{
                  ...currentUser,
                  profile_image_url: profileImageUrl,
                  profile_name: profileName,
                  bio: bio,
                }}
                theme={selectedTheme.theme}
                buttonStyle={selectedButtonStyle}
                hoverEffect={selectedHoverEffect}
                layoutStyle={selectedLayoutStyle}
                font={selectedFont}
                width="300px"
                height="calc(100vh - 200px)"
              />
            </div>
          </div>
        </div>
        <div class="border-t border-gray-200 px-4 py-3 bg-gray-50">
          <button
            type="button"
            on:click={togglePreviewModal}
            class="w-full py-2 px-4 bg-purple-600 text-white rounded-md shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition duration-150 ease-in-out"
          >
            Close Preview
          </button>
        </div>
      </div>
    </div>
  {/if}
</Dashboard>
