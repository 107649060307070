<script>
  import { createEventDispatcher } from "svelte";
  import Actions from "$app-components/blocks/Actions.svelte";

  const dispatch = createEventDispatcher();

  export let block;
  export let updater;

  function handleDelete() {
    dispatch("delete-block", { id: block.id });
  }

  $: ({ enabled } = block);
</script>

<div class="flex flex-col bg-white rounded-lg shadow">
  <div class="flex items-center justify-between p-4 h-32">
    <div class="flex items-center space-x-4 flex-grow overflow-hidden">
      <div class="cursor-move">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>
      <div class="h-24 flex items-center justify-start overflow-hidden">
        {#if block.image_url}
          <img
            src={block.image_url}
            alt={block.alt_text || "Image"}
            class="h-full w-auto object-contain"
          />
        {:else}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-16 w-16 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        {/if}
      </div>
    </div>
    <Actions {block} {updater} on:delete-block={handleDelete} />
  </div>
</div>
