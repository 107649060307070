<script>
  import Alert from "$components/Alert.svelte";
  import Ajax from "$utils/ajax";
  import blockTypes from "./_blocks.js";

  export let block;
  export let updater;

  let showDeleteAlert = false;
  let BlockComponent = blockTypes[block.block_type]?.component;

  const openDeleteAlert = () => {
    showDeleteAlert = true;
  };

  const closeDeleteAlert = () => {
    showDeleteAlert = false;
  };

  const handleDelete = async () => {
    let [errors, _resp] = await Ajax.delete(`/dashboard/blocks/${block.id}`);

    if (errors) {
      notify({
        type: "error",
        message: "Failed to delete block. We will look into this.",
      });
    } else {
      updater(({ blocks }) => {
        return { blocks: blocks.filter((b) => b.id !== block.id) };
      });

      closeDeleteAlert();
    }
  };
</script>

{#if BlockComponent}
  <div id={`block-${block.id}`}>
    <svelte:component
      this={BlockComponent}
      {block}
      {updater}
      on:delete-block={openDeleteAlert}
    />
  </div>

  <Alert
    bind:isOpen={showDeleteAlert}
    title="Delete Block"
    message="Are you sure you want to delete this block? This action cannot be undone."
    type="warning"
    showCloseButton={true}
    primaryAction="Delete"
    secondaryAction="Cancel"
    on:close={closeDeleteAlert}
    on:primaryAction={handleDelete}
    on:secondaryAction={closeDeleteAlert}
  />
{/if}
