<script>
  import Dashboard from "$layouts/Dashboard.svelte";

  import Lifetime from "./analytics/Lifetime.svelte";
  import TopBlocks from "./analytics/TopBlocks.svelte";
  import Locations from "./analytics/Locations.svelte";
  import Devices from "./analytics/Devices.svelte";
  import SocialLinks from "./analytics/SocialLinks.svelte";

  import { onMount } from "svelte";

  import "chartkick/chart.js";

  export let lifetimeStats;
  export let topBlocks;
  export let locations;
  export let devices;
  export let socialLinks;
  export let dailyViewsChart;
  export let dailyClicksChart;

  let parsedCharts = {};
  let currentMonthName = new Date()
    .toLocaleString("default", {
      month: "long",
    })
    .toLowerCase();

  function parseChart(chartHtml) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(chartHtml, "text/html");

    return {
      html: doc.body.innerHTML,
      script: doc.querySelector("script")?.textContent || "",
    };
  }

  function executeChartScript(script) {
    const scriptElement = document.createElement("script");
    scriptElement.textContent = script;
    document.body.appendChild(scriptElement);
  }

  function drawCharts() {
    Object.values(parsedCharts).forEach((chart) => {
      if (chart.script) {
        executeChartScript(chart.script);
      }
    });
  }

  onMount(() => {
    parsedCharts.dailyViewsChart = parseChart(dailyViewsChart);
    parsedCharts.dailyClicksChart = parseChart(dailyClicksChart);

    // Execute the scripts after the component has mounted and the elements exist
    setTimeout(drawCharts, 0);

    // Add event listener for window resize
    window.addEventListener("resize", drawCharts);

    // Clean up the event listener when the component is destroyed
    return () => {
      window.removeEventListener("resize", drawCharts);
    };
  });
</script>

<Dashboard>
  <div class="mb-10">
    <Lifetime {lifetimeStats} />
  </div>

  <div class="mb-12">
    <h2 class="text-xl font-bold mb-4">Activity</h2>
    <div class="flex flex-col md:flex-row gap-4">
      <div class="flex-1 bg-white p-4 rounded-lg shadow">
        <h3 class="text-lg font-semibold mb-6">Daily Views</h3>
        {@html parsedCharts.dailyViewsChart?.html || ""}
      </div>

      <div class="flex-1 bg-white p-4 rounded-lg shadow">
        <h3 class="text-lg font-semibold mb-6">Daily Clicks</h3>
        {@html parsedCharts.dailyClicksChart?.html || ""}
      </div>
    </div>
  </div>

  <div class="mb-12">
    <h2 class="text-xl font-bold mb-4">
      Top blocks
      <span
        class="text-lg font-normal text-gray-500 ml-3 bg-gray-100 px-3 py-1 rounded-full"
      >
        {currentMonthName}
      </span>
    </h2>
    <TopBlocks {topBlocks} />
  </div>

  <div class="mb-12">
    <h2 class="text-xl font-bold mb-4 flex items-center">
      Locations
      <span
        class="text-lg font-normal text-gray-500 ml-3 bg-gray-100 px-3 py-1 rounded-full"
      >
        {currentMonthName}
      </span>
    </h2>
    <Locations {locations} />
  </div>

  <div class="mb-12">
    <h2 class="text-xl font-bold mb-4">
      Devices
      <span
        class="text-lg font-normal text-gray-500 ml-3 bg-gray-100 px-3 py-1 rounded-full"
      >
        {currentMonthName}
      </span>
    </h2>
    <Devices {devices} />
  </div>

  <div class="mb-12">
    <h2 class="text-xl font-bold mb-4">
      Social links
      <span
        class="text-lg font-normal text-gray-500 ml-3 bg-gray-100 px-3 py-1 rounded-full"
      >
        {currentMonthName}
      </span>
    </h2>
    <SocialLinks links={socialLinks} />
  </div>
</Dashboard>
