<script>
  import { createEventDispatcher } from "svelte";

  import Button from "$components/Button.svelte";
  import Input from "$components/Input.svelte";
  import Form from "$components/Form.svelte";
  import Select from "$components/Select.svelte";
  import Ajax from "$utils/ajax";

  const dispatch = createEventDispatcher();

  export let block = null;

  let text = block?.title || "";
  let level = block?.fields?.level || "h3";
  let saving = false;

  let errors = {};

  const headingLevels = [
    { value: "h1", label: "H1 (biggest)" },
    { value: "h2", label: "H2" },
    { value: "h3", label: "H3" },
    { value: "h4", label: "H4" },
    { value: "h5", label: "H5" },
    { value: "h6", label: "H6 (smallest)" },
  ];

  const saveHeading = async (event) => {
    event.preventDefault();

    if (saving) return;
    saving = true;

    const data = {
      block_type: "heading",
      title: text,
      fields: { level },
    };

    const [ajaxErrors, resp] = block
      ? await Ajax.put(`/dashboard/blocks/${block.id}`, data)
      : await Ajax.post("/dashboard/blocks", data);

    if (ajaxErrors) {
      errors = ajaxErrors;
    } else {
      if (!block) {
        text = "";
        level = "h2";
      }

      if (block) {
        dispatch("edited-block", resp);
      } else {
        dispatch("added-block", resp);
      }
      dispatch("close");
    }

    saving = false;
  };
</script>

<h2 class="text-xl font-bold text-gray-800 mb-12 mt-4 text-center">
  {block ? "Edit" : "Add"} Heading
</h2>

<Form on:submit={saveHeading}>
  <div class="flex flex-col gap-8">
    <Input
      bind:value={text}
      type="text"
      name="text"
      placeholder="Enter heading text"
      required
      klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
      error={errors.title}
    />
    <div>
      <Select
        bind:value={level}
        options={headingLevels}
        label="Heading Level"
        klass="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:border-purple-300 transition duration-200 ease-in-out !text-lg"
        error={errors.level}
      />
    </div>
    <Button type="submit" klass="w-full h-12" disabled={saving}>
      {#if saving}
        <span class="inline-block animate-spin mr-2">&#8635;</span>
      {/if}
      {block ? "Update" : "Add"} Heading
    </Button>
  </div>
</Form>
